import { FC, createContext } from 'react';

export interface DialogContextProps {
  isDialogOpen: string | false;
  setIsDialogOpen: (dialog: string | false) => void;
}

export const DialogContext = createContext<DialogContextProps>({
  isDialogOpen: false,
  setIsDialogOpen: () => {},
});
