import type { WWWSearchRequest, WWWSearchItem } from '../common/documents/WWWSearch';
import { SearchCacheEntryTTL, SearchCacheKey } from '../common/Constants';
import { SearchProcessState, searchState, getSearchQueryParameters } from './searchStore';
import { loginContext } from './loginContext';

/**
 * Cached search data stored in local storage for instant back and history navigation
 */
interface SearchCacheEntry {
  // Hash calculated from the search request parameters
  hash: string;

  text: string;
  page: number;

  // Time this search was last accessed
  timestamp: number;

  // The search results
  items: WWWSearchItem[];

  // Total items
  total: number;

  // Was the query premium blocked
  premium: boolean;
}

const queryToSearchCacheHash = (query: Partial<WWWSearchRequest>) => {
  const accountCode = loginContext.get().account?.accountCode || null;
  const { from, size, ...serializableQuery } = query;

  // A really simple way to create a hash from search query
  const objectHash = Object.entries({ ...serializableQuery, accountCode })
    .filter(([, value]) => !(value === null || (Array.isArray(value) && !value.length)))
    .sort(([a], [b]) => a.localeCompare(b))
    .map(
      ([key, entry]) =>
        `${key}=${Array.isArray(entry) ? entry.join(',') : typeof entry === 'object' ? Object.values(entry).join(',') : entry}`
    )
    .join('&');

  return objectHash;
};

export const updateSearchCache = () => {
  try {
    let searchCache: { [hash: string]: SearchCacheEntry } = JSON.parse(sessionStorage.getItem(SearchCacheKey));

    if (!searchCache) searchCache = {};

    const { currentSearchQuery, searchPage, searchItems, searchItemsTotal, isPremiumBlock } = searchState.getState();
    const hash = queryToSearchCacheHash(currentSearchQuery);

    const searchCacheEntry: SearchCacheEntry = {
      hash,
      text: currentSearchQuery.fullTextSearch || '',
      timestamp: Date.now(),
      items: searchItems,
      total: searchItemsTotal,
      premium: isPremiumBlock,
      page: searchPage,
    };

    // Entry already exists, update it
    if (searchCache[hash]) {
      searchCache[hash] = searchCacheEntry;
      sessionStorage.setItem(SearchCacheKey, JSON.stringify(searchCache));
      return;
    }

    // Search cache is full, remove the oldest entry
    if (Object.keys(searchCache).length >= 3) {
      const { hash: oldestSearchEntryHash } = Object.values(searchCache).sort((a, b) => a.timestamp - b.timestamp)[0];
      delete searchCache[oldestSearchEntryHash];
    }

    searchCache[hash] = searchCacheEntry;
    sessionStorage.setItem(SearchCacheKey, JSON.stringify(searchCache));
  } catch (error) {
    console.error('Could not update search cache', error);
    sessionStorage.removeItem('search.cache');
  }
};

export const checkSearchCache = ({ text, page }: { text: string; page: number }): SearchCacheEntry | null => {
  const searchQuery = getSearchQueryParameters({ text, page });
  const hash = queryToSearchCacheHash(searchQuery);

  const searchCache: { [hash: string]: SearchCacheEntry } = JSON.parse(sessionStorage.getItem(SearchCacheKey));

  if (!searchCache) return null;

  // If we found an entry, and it has loaded all the pages required, return it
  if (searchCache[hash] && searchCache[hash].page <= page) {
    if ((Date.now() - searchCache[hash].timestamp) / 1000 > SearchCacheEntryTTL) {
      // If cached results are older than 30 minutes, ignore them
      return null;
    }

    return searchCache[hash];
  }

  return null;
};

export const restoreSearchStateFromCache = (cacheEntry: SearchCacheEntry) => {
  const { text, items, total, premium, page } = cacheEntry;
  searchState.setState({
    searchText: text,
    state: SearchProcessState.DATA,
    isSearchLoading: false,
    isPremiumBlock: premium,
    searchItems: items,
    searchItemsTotal: total,
    searchPage: page,
  });

  const searchQuery = getSearchQueryParameters({ text, page });
  searchState.setState({ currentSearchQuery: searchQuery });
};
