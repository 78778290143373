import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DiamondIcon from 'mdi-react/DiamondIcon';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import LoginIcon from 'mdi-react/LoginVariantIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import { useScopedState } from '../../lib/state';
import { gaEvent } from '../../lib/ga';
import { useUser } from '../../context/useUser';
import { AuthLoginPoll } from '../auth/AuthLoginPoll';
import { paywallState } from '../../context/paywallStore';
import { startTrial } from '../../context/loginContext';
import { Button } from '../Button';
import Modal from '../Modal';
import { Help } from '../Help';
import { Error } from '../Error';
import { Banner } from '../Banner';
import { Color, layoutStyles } from '../Theme';

const Gem = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -32px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: white;
`;

const Container = styled.div`
  ${layoutStyles}
  padding: 4rem 0 8rem 0;

  h2,
  h4,
  p {
    text-align: center;
  }

  .error p,
  .success p {
    text-align: left;
  }

  .success {
    margin-top: 32px;
    margin-bottom: 8px !important;
  }

  h2 {
    margin-top: 16px;
  }

  .MuiTypography-body1 {
    margin: 32px 0 0 0;
    opacity: 0.8;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;

  > a {
    margin: 0 !important;
  }
`;

const LargeButton = styled(Button)`
  margin-top: 16px !important;
  margin-left: 0 !important;
  padding: 22px !important;
  width: 100%;
  border-radius: 12px !important;
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  top: 8px;
  right: 8px;
`;

const SuccessBanner = styled.div`
  > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    h6 {
      margin: 16px 0;
    }

    > p > p {
      margin-top: 0 !important;
      margin-bottom: 16px !important;
      text-align: center !important;
    }

    > svg {
      margin-top: 16px;
      width: 48px;
      height: 48px;
    }
  }
`;

/**
 * Shows a cookie consent banner upon first visit
 * NOTE: This should only ever be included with client side code, NEVER with SSR!
 */
const PaywallDrawer: React.FC = ({}) => {
  const { isLoggedIn } = useUser();
  const { pathname, push } = useRouter();
  const { isOpen } = useScopedState(paywallState, ['isOpen']);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | JSX.Element | null>(null);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const isPremiumPage = pathname === '/premium';

  useEffect(() => {
    if (pathname === '/premium') paywallState.setState({ isOpen: false });
    if (pathname === '/login') paywallState.setState({ isOpen: false });
  }, [pathname]);

  const handleStartTrial = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    gaEvent('paywall_start_trial_button', { event_category: 'engagement', event_label: 'paywall start tiral button pressed' });

    setLoading(true);

    const { status, error, token } = await startTrial({ email, url: pathname });

    if (status !== 'ok') {
      setLoading(false);
      return setError(error);
    }

    setToken(token);
  };

  const handleTrialStarted = useCallback(() => setComplete(true), []);

  const handleClose = () => {
    gaEvent('paywall_close', { event_category: 'engagement', event_label: 'paywall closed' });
    paywallState.setState({ isOpen: false, isClosed: true });
  };

  const handlePremiumClose = () => {
    paywallState.setState({ isOpen: false });
  };

  const handleInfoClick = () => {
    gaEvent('paywall_info_button', { event_category: 'engagement', event_label: 'paywall info button pressed' });
    push('/premium');
  };

  const handlePriceClick = () => {
    gaEvent('paywall_price_button', { event_category: 'engagement', event_label: 'paywall price button pressed' });
    push('/premium');
  };

  const handleCallClick = () => {
    gaEvent('paywall_call_button', { event_category: 'engagement', event_label: 'paywall call button pressed' });
    window.location.href = 'https://calendly.com/constle/constle-esittely';
  };

  const handleLoginClick = () => {
    gaEvent('paywall_login_button', { event_category: 'engagement', event_label: 'paywall login button pressed' });
    push('/login');
  };

  return (
    <Drawer
      open={isOpen}
      elevation={0}
      PaperProps={{ style: { background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))' } }}
      ModalProps={{ BackdropProps: { style: { background: 'rgba(0, 0, 0, 0.1)' } } }}
      transitionDuration={{ enter: 700 }}
      variant="temporary"
      anchor="bottom"
    >
      <Container>
        <Modal
          title="Tarjoatko tai ostatko rakennusalan urakointia?"
          description="Constlessa KiRa-alan ammattilaiset etsivät urakoitsijoita yli 100.000 alan yrityksen joukosta täysin ainutlaatuisella nopeudella, tarkkuudella  ja varmuudella. Constle on ammattilaisille korvaamaton apu - mikäli yrityksesi toimii KiRa -alalla voit kirjautumalla työsähköpostiosoitteellasi kokeilla Constlea nyt 14 päivää ilmaiseksi!"
          style={{ position: 'relative', borderRadius: 12 }}
        >
          {(isLoggedIn || isPremiumPage) && (
            <CloseButton aria-label="sulje" onClick={isPremiumPage ? handlePremiumClose : handleClose}>
              <CloseIcon />
            </CloseButton>
          )}

          <Modal.Content>
            <Gem>
              <DiamondIcon size={32} color={Color.primary} />
            </Gem>

            <Links>
              <Button
                href="/premium"
                color="default"
                size="small"
                startIcon={<InformationOutlineIcon size={18} />}
                onClick={handleInfoClick}
              >
                Lisätietoja
              </Button>
              <Button color="default" size="small" startIcon={<PhoneIcon size={18} />} onClick={handleCallClick}>
                Varaa esittely
              </Button>
              <Button color="default" size="small" startIcon={<CreditCardIcon size={18} />} onClick={handlePriceClick}>
                Hinnoittelu
              </Button>
              {!isLoggedIn && (
                <Button color="default" size="small" startIcon={<LoginIcon size={18} />} onClick={handleLoginClick}>
                  Kirjaudu sisään
                </Button>
              )}
            </Links>

            <hr />

            {!token && !complete && <Typography>Haluatko jatkaa käyttöä heti?</Typography>}

            {!token && !complete && !isLoggedIn && (
              <form onSubmit={handleStartTrial}>
                <TextField
                  label={<FormattedMessage id="register.form.email" defaultMessage="Sähköpostiosoite" />}
                  variant="outlined"
                  type="email"
                  autoComplete="username"
                  onChange={(event) => setEmail(event.target.value)}
                  fullWidth
                />

                <Error error={error} />

                <LargeButton loading={loading} type="submit">
                  <FormattedMessage id="paywall.registerButton" defaultMessage="Aloita 14-päivän ilmainen kokeilu" />
                </LargeButton>

                <Help>
                  <FormattedMessage id="paywall.footerInfo" defaultMessage="Kokeilu alkaa heti eikä muita toimenpiteitä tarvita." />
                </Help>
              </form>
            )}

            {!complete && isLoggedIn && (
              <LargeButton href="/premium">
                <FormattedMessage id="paywall.getPremiumButton" defaultMessage="Hanki Constle Pro -jäsenyys" />
              </LargeButton>
            )}

            {!!token && !complete && <AuthLoginPoll token={token} isDialog onLogin={handleTrialStarted} />}

            {complete && (
              <>
                <SuccessBanner>
                  <Banner type="success">
                    <Typography variant="h6">Tili luotu ja jäsenyys aloitettu onnistuneesti!</Typography>
                    <Typography>
                      Saat vielä vahvistusviestin sähköpostiisi.
                      <br />
                      Voit nyt jatkaa Constlen käyttöä.
                    </Typography>
                  </Banner>
                </SuccessBanner>
                <LargeButton onClick={() => paywallState.setState({ isOpen: false })}>
                  <FormattedMessage id="paywall.completeButton" defaultMessage="OK" />
                </LargeButton>
              </>
            )}
          </Modal.Content>
        </Modal>
      </Container>
    </Drawer>
  );
};

/** Has to be a default export to use Next.js dynamic */
export default PaywallDrawer;
