import create from 'zustand';
import { combine } from 'zustand/middleware';
import { WWWClassificationState } from '../lib/classifications';

export type StaticState = WWWClassificationState;

export const staticStateDefaults: StaticState = {
  contractingForms: [],
  languages: [],
  machineryTypes: [],
  operationalAreas: [],
  projectTypes: [],
  taitoCompetences: [],
  talo80: [],
  targetGroups: [],
  tol2008: [],
};

/** Store */
export const staticState = create(combine(staticStateDefaults, (set) => ({ set })));
