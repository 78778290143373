import React, { FC } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import InformationIcon from 'mdi-react/InformationOutlineIcon';
import { useIntl } from 'react-intl';
import { Logo } from '../Logo';
import { Badge } from '../Badge';
import { Color, layoutStyles, mediaQuery, navbarHeight } from '../Theme';
import { useNavigationItems } from './navigationItems';
import { useClearSearch } from '../../context/searchStore';

const Styled = {
  Bar: styled.header<{ dialogPage: boolean; heroPage: boolean }>`
    position: ${({ dialogPage, heroPage }) => (dialogPage || heroPage ? 'absolute' : 'relative')};
    height: ${navbarHeight}px;
    width: 100%;
    background-color: ${({ dialogPage, heroPage }) => (dialogPage || heroPage ? 'transparent' : Color.secondary)};
    overflow: hidden;

    .badge {
      display: none; // TODO: maybe still show this at staging?
      position: absolute;
      margin-left: 130px;
    }

    .beta {
      transform: scale(0.75);
    }

    ${({ heroPage }) =>
      mediaQuery.small(css`
        background-color: ${heroPage ? 'transparent' : Color.secondary};
      `)}

    h1 {
      color: ${Color.primary};
    }

    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0 1rem;
        font-weight: bold;
        color: ${Color.white};

        a {
          display: flex;
          align-items: center;

          svg {
            color: ${Color.primary};
          }
        }
      }
    }
  `,

  Content: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    > h1 a {
      display: flex;
    }

    li {
      justify-content: flex-end;
      margin: 0px;
    }
    li * {
      margin: 2px;
    }
    ${layoutStyles}
  `,

  Actions: styled.div<{ desktop?: boolean; mobile?: boolean }>`
    > .MuiButtonBase-root {
      margin-left: 2rem;
    }

    ${({ mobile }) =>
      mobile &&
      css`
        display: none;
      `}

    ${({ desktop, mobile }) =>
      mediaQuery.small(css`
        ${desktop &&
        css`
          display: none;
        `}

        ${mobile &&
        css`
          display: block;
        `}
      `)}
  `,

  MobileMenu: styled.div``,

  MobileMenuButton: styled.div`
    display: none;

    ${mediaQuery.small(css`
      display: block;
    `)}
  `,
};

export const Navbar: FC<{ dialogPage?: boolean; heroPage?: boolean; openOnboardingDialog: () => void }> = ({
  dialogPage = false,
  heroPage = false,
  openOnboardingDialog,
}) => {
  const intl = useIntl();
  const { doClearSearch } = useClearSearch();

  const isFullPageLayout = dialogPage || heroPage;

  const logoLinkLabel = intl.formatMessage({
    id: 'navigation.logoLinkLabel',
    defaultMessage: 'etusivu',
  });

  const { renderDesktopNavigation, renderMobileNavigation } = useNavigationItems();

  return (
    <>
      <Styled.Bar className={classNames({ 'mui-fixed': isFullPageLayout })} dialogPage={dialogPage} heroPage={heroPage}>
        <Styled.Content>
          <h1 onClick={doClearSearch}>
            <Link href="/">
              <a aria-label={logoLinkLabel}>
                <Logo />
              </a>
            </Link>
          </h1>

          <Badge icon={<InformationIcon size={14} />} className="beta">
            BETA
          </Badge>

          {/* Visible on desktop */}
          <Styled.Actions desktop>{renderDesktopNavigation()}</Styled.Actions>

          {/* Visible on mobile */}
          <Styled.Actions mobile>{renderMobileNavigation()}</Styled.Actions>
        </Styled.Content>
      </Styled.Bar>
    </>
  );
};
