import { useCallback } from 'react';
import create, { GetState, SetState } from 'zustand';
import { combine } from 'zustand/middleware';
import shallow from 'zustand/shallow';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import pick from 'lodash/pick';
import { nextApiRequest } from '../lib/utils';
import type { WWWSearchRequest, WWWSearchItem } from '../common/documents/WWWSearch';
import { SearchPageResultCount } from '../common/Constants';
import { advancedSearchState, advancedSearchStateDefaults, getAdvancedSearchQueryParameters } from './advancedSearchStore';
import { updateSearchCache } from './searchCache';
import { useScopedState } from '../lib/state';

export enum SearchProcessState {
  FRESH = 0,
  LOADING = 1,
  DATA = 3,
}

export interface SearchPageState {
  items: WWWSearchItem[];
  expectedItems?: number;
  isLoading: boolean;
}

export type SearchState = {
  searchText: string;
  searchPage: number;
  state: SearchProcessState;
  isSearchLoading: boolean;
  isSearchMoreLoading: boolean;
  isPremiumBlock: boolean;

  // Will be incremented by 1 on each search
  searchIndex: number;

  // Parameters used to get the current results
  currentSearchQuery: Partial<WWWSearchRequest> | null;

  // Sort
  sortField: WWWSearchRequest['sortField'];
  sortDirection: WWWSearchRequest['sortDirection'];

  // Items shown on search page
  searchItems: WWWSearchItem[];
  searchItemsTotal: number;
  searchError: JSX.Element | null;

  // Increment this by one every time a new search query should be done
  searchIncrement: number;

  isAdvancedSearchOpen: boolean;
};

export const searchStateDefaults: SearchState = {
  searchText: '',
  searchPage: 0,
  state: SearchProcessState.FRESH,
  isSearchLoading: false,
  isSearchMoreLoading: false,
  isPremiumBlock: false,

  searchIndex: 0,
  currentSearchQuery: null,

  sortField: 'default',
  sortDirection: 'descending',

  searchItems: [],
  searchItemsTotal: 0,
  searchError: null,

  searchIncrement: 0,

  isAdvancedSearchOpen: false,
};

export type StateAction<T = {}, D = {}> = (
  set: SetState<SearchState>,
  get: GetState<SearchState>
) => (data?: T) => Promise<{ status: string; error?: string | JSX.Element } & D>;

/**
 * Do a search
 */
export const doSearch =
  (set: SetState<SearchState>, get: GetState<SearchState>) =>
  async (text: string = null) => {
    // Set loading state right away
    set({ isSearchLoading: true, state: SearchProcessState.LOADING, searchIndex: get().searchIndex + 1 });

    // Update search text if requested.
    // This is useful if doSearch is called from page load or suggestion for example.
    if (text !== null) {
      set({ searchText: text });
    }

    const searchText = text || get().searchText;

    // Convert current state to search query parameters
    const searchParameters = getSearchQueryParameters({ text: searchText, page: 0 });

    // Call the search endpoint
    const {
      status: searchStatus,
      items: searchItems,
      totalFound: searchItemsTotal,
      isPremiumBlock,
    } = await nextApiRequest('/api/v1/www/search', searchParameters, { delay: 200 });

    if (searchStatus !== 'ok') {
      return set({
        state: SearchProcessState.DATA,
        isSearchLoading: false,
        searchError: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
      });
    }

    set({
      searchPage: 0,
      state: SearchProcessState.DATA,
      isSearchLoading: false,
      isPremiumBlock,
      currentSearchQuery: searchParameters,
      searchItems,
      searchItemsTotal,
      searchError: null,
    });

    updateSearchCache();
  };

/**
 * Load more results
 */
export const doLoadMore = (set: SetState<SearchState>, get: GetState<SearchState>) => async (page: number) => {
  const { currentSearchQuery, searchItems: currentSearchItems } = get();

  // Set loading state right away
  set({ isSearchMoreLoading: true });

  const searchPage = page;
  const searchQuery = {
    ...currentSearchQuery,

    from: searchPage * SearchPageResultCount,
    size: SearchPageResultCount,
  };

  // Call the search endpoint
  const { status: searchStatus, items: newSearchItems } = await nextApiRequest('/api/v1/www/search', searchQuery, { delay: 200 });

  if (searchStatus !== 'ok') {
    return set({
      isSearchMoreLoading: false,
      searchError: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    });
  }

  const searchItems = [...currentSearchItems, ...newSearchItems];

  set({
    searchPage,
    isSearchMoreLoading: false,
    currentSearchQuery: searchQuery,
    searchItems,
    searchError: null,
  });

  updateSearchCache();
};

/** Store */
export const searchState = create(
  combine(searchStateDefaults, (set, get) => ({
    /** State methods */
    doSearch: doSearch(set, get),
    doLoadMore: doLoadMore(set, get),

    /** General set state */
    set,
  }))
);

/**
 * Convert a search data into search API request parameters
 */
export const getSearchQueryParameters = ({ text, page }: { text: string; page: number }): WWWSearchRequest => {
  const { sortField, sortDirection } = searchState.getState();

  const advancedSearchQueryParameters = getAdvancedSearchQueryParameters();

  return {
    from: page * SearchPageResultCount,
    size: SearchPageResultCount,

    sortField,
    sortDirection,

    fullTextSearch: text || '',

    ...advancedSearchQueryParameters,
  };
};

/** Hook to get search parameters from the page URL */
export const useSearchURL = () => {
  const { query } = useRouter();

  let queryText = '';
  let queryPage = 0;

  // The router query only populates after the first render,
  // so we have to get the parameters from the page URL if
  // we are in the browser...
  if (process.browser) {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    queryText = queryParams.get('q') || '';
    queryPage = parseInt(queryParams.get('p')) || 0;
  }

  // If the query has been populated, always use it for accurate values
  if (query && (query.q || query.p)) {
    queryText = (query.q as string) || '';
    queryPage = parseInt(query.p as string) || 0;
  }

  return { queryText, queryPage };
};

/** Hook to allow company row to edit some of it's details and update when these details change */
export const useEditSearchItem = (companyID: number, isUpsert: boolean = false) => {
  const { set } = useScopedState(searchState, []);
  const itemStateToWatch: readonly (keyof WWWSearchItem)[] = ['companyID', 'isLiked', 'isDisliked', 'listIDs'] as const;

  const itemState = searchState(
    (state) => state.searchItems.find((item) => item.companyID === companyID),
    (a, b) => shallow(pick(a, itemStateToWatch), pick(b, itemStateToWatch))
  );

  const doEdit = ({
    isLiked = null,
    isDisliked = null,
    listIDs = null,
  }: {
    isLiked?: boolean;
    isDisliked?: boolean;
    listIDs?: number[];
  }) => {
    const { searchItems } = searchState.getState();
    const itemIndex = searchItems.findIndex((company) => company.companyID === companyID);
    if (itemIndex < 0) {
      if (!isUpsert) {
        console.error(
          `Tried to update company (${companyID}) but it was not found in search results`,
          'This is most likely due to useCallback or other memorization caching an old companyID'
        );
      }
      return;
    }

    const searchItem = { ...searchItems[itemIndex] };

    if (isLiked !== null) {
      searchItem.isLiked = isLiked;
    }

    if (isDisliked !== null) {
      searchItem.isDisliked = isDisliked;
    }

    if (listIDs !== null) {
      searchItem.listIDs = listIDs;
    }

    // NOTE: Updating state reference directly here to avoid re-renders. This is potentially dangerous...
    searchItems[itemIndex] = searchItem;

    // Update state
    set({ searchItems: [...searchItems] });

    // Update cache state
    updateSearchCache();
  };

  const { isLiked, isDisliked, listIDs } = itemState || { isLiked: false, isDisliked: false, listIDs: [] };

  return { doEdit, isLiked, isDisliked, listIDs };
};

/** Hook to make getting and setting search ordering easy */
export const useSearchOrder = () => {
  const { sortField, sortDirection, doSearch, set } = useScopedState(searchState, ['sortField', 'sortDirection']);

  const setSort = async (sort: { sortField?: SearchState['sortField']; sortDirection?: SearchState['sortDirection'] }) => {
    const { sortField: field, sortDirection: direction } = { sortField, sortDirection, ...sort };

    if (sortField === field && sortDirection === direction) {
      return;
    }

    set({ sortField: field, sortDirection: direction });

    await doSearch();
  };

  return { sortField, sortDirection, setSort };
};

/** Hook to make clearing entire search easy */
export const useClearSearch = () => {
  const { set: setSearchState } = useScopedState(searchState, []);
  const { set: setAdvancedSearchState } = useScopedState(advancedSearchState, []);

  const doClearSearch = useCallback(() => {
    setAdvancedSearchState({ ...advancedSearchStateDefaults });
    setSearchState({ searchText: '', currentSearchQuery: null });
  }, [setSearchState, setAdvancedSearchState]);

  return {
    doClearSearch,
  };
};
