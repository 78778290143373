import { FC, useState, useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { DialogContext } from '../../context/dialogContext';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { mediaQuery } from '../Theme';

const Styled = {
  Container: styled.div<{ isDialogOpen: boolean }>`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    ${(props) =>
      props.isDialogOpen &&
      css`
        min-height: auto;
        max-height: 100%;
        height: 100%;
        overflow: hidden;
      `}

    ${mediaQuery.small(css`
      min-height: auto;
    `)}
  `,
  Content: styled.main<{ fullPage?: boolean }>`
    flex: 1;
    flex-shrink: 0;
  `,
};

export interface LayoutProps {
  history: { path: string; query: any }[];
}

const includeOmnibar = ['/search', '/lists', '/company/[hash]/[slug]', '/contact-persons'];
const includeMobileBar = ['/lists', '/company/[hash]/[slug]'];

const dialogPages = ['/login', '/register', '/email', '/contact-us', '/invite'];
const heroPages = ['/premium', '/about-us'];

const FakeOmnibar: FC = () => <div style={{ height: 66, background: '#fff' }} />;

export const Layout: FC<LayoutProps> = ({ history, children }) => {
  const router = useRouter();
  const [isDialogOpen, setIsDialogOpen] = useState<string | false>(false);

  const isSmall = useBreakPoint('small', true);

  let isOmnibarPresent = includeOmnibar.includes(router.route);
  const isMobileBarPresent = includeMobileBar.includes(router.route);
  const isBackToSearchPossible = router.route === '/company/[hash]/[slug]' && history[history.length - 2]?.path === '/search';

  if (isSmall && isMobileBarPresent) isOmnibarPresent = false;

  const isDialogPage = dialogPages.filter((x) => router.route.includes(x)).length > 0;
  const isHeroPage = router.route === '/' || heroPages.filter((x) => router.route.includes(x)).length > 0;

  // Import omnibar only if required
  const Omnibar = useMemo(
    () =>
      isOmnibarPresent
        ? dynamic(() => import('../omnibar/Omnibar').then(({ Omnibar }) => Omnibar), { loading: () => <FakeOmnibar /> })
        : null,
    [isOmnibarPresent, isSmall]
  );

  // Import mobile bar only if required
  const MobileBar = useMemo(
    () =>
      isMobileBarPresent
        ? dynamic(() => import('../search/MobileBar').then(({ MobileBar }) => MobileBar), { loading: () => <FakeOmnibar /> })
        : null,
    [isMobileBarPresent]
  );

  const handleOpenOnboardingDialog = () => {
    setIsDialogOpen('onboarding');
  };

  // To open the onboarding after register
  // TODO: There should be a better way...
  useEffect(() => {
    if (router.route === '/' && router.asPath.includes('?onboarding=1')) {
      router.replace('/');
      handleOpenOnboardingDialog();
    }
  }, [router.route]);

  return (
    <DialogContext.Provider value={{ isDialogOpen, setIsDialogOpen }}>
      <Styled.Container className="page" isDialogOpen={!!isDialogOpen}>
        <Navbar dialogPage={isDialogPage} heroPage={isHeroPage} openOnboardingDialog={handleOpenOnboardingDialog} />

        {isOmnibarPresent && <Omnibar fullPage hasMobileBar={isMobileBarPresent} />}
        {isMobileBarPresent && <MobileBar showBack={isBackToSearchPossible} />}

        <Styled.Content fullPage={isDialogPage || isHeroPage}>{children}</Styled.Content>
        <Footer dialogPage={isDialogPage} />
      </Styled.Container>
    </DialogContext.Provider>
  );
};
