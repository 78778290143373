import { ClassificationItem, ClassificationStateItem, ClassificationState } from '../common/documents/ClassificationDocument';
import { convertClassificationItemsToState, validateSelectedClassificationIDs } from '../common/classificationCommon';

// NOTE: Do not modify this! Pro will crash if import is changed.
import { pick, isEqual } from 'lodash';

export interface SelectItemProps {
  id: number | string;
  parentID?: number | string | null;
  name: string;
  nameTranslations: { [locale: string]: string };
}

export type SelectOption = Omit<
  ClassificationStateItem<{
    index: number;
    level: number;
    isOpen: boolean;
    isSelected: boolean;
    selectedChildCount: number;
    isFocused: boolean;
    isVisible: boolean;
    isMatching: boolean;
    isTouched: boolean;
  }>,
  'id' | 'parents' | 'children'
> & {
  id: string;
  parents: string[];
  children: string[];
};

export type SelectState = { [id: string]: SelectOption };

export type SelectItemDictionary = { [id: string]: SelectOption };

export const compareSelectItems = (prev: SelectOption, next: SelectOption) => {
  const fieldsToDiff: (keyof SelectOption)[] = [
    'id',
    'index',
    'isOpen',
    'isSelected',
    'isFocused',
    'isVisible',
    'isMatching',
    'selectedChildCount',
  ];
  return isEqual(pick(prev, fieldsToDiff), pick(next, fieldsToDiff));
};

/** The API side uses numeric IDs instead of strings, this makes it easier to use common functions */
const convertSelectStateToClassificationState = (itemDictionary: SelectItemDictionary): ClassificationState => {
  const numericState: ClassificationState = {};
  for (const item of Object.values(itemDictionary)) {
    numericState[parseInt(item.id)] = {
      id: parseInt(item.id),
      name: item.name,
      parents: item.parents.map((parent) => parseInt(parent)),
      children: item.children.map((child) => parseInt(child)),
    };
  }
  return numericState;
};

export const filterOption = (option: SelectOption, search: string, itemDictionary: SelectItemDictionary) => {
  const matchingIDs: string[] = [];

  /** If this option matches */
  if (option.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
    matchingIDs.push(option.id);
  }

  /** If any of the children match (recursive) */
  const matchingChildIDs = [];
  if (option.children && option.children.length) {
    for (const child of option.children) {
      matchingChildIDs.push(...filterOption(itemDictionary[child], search, itemDictionary));
    }

    if (matchingChildIDs.length > 0) {
      matchingIDs.push(...matchingChildIDs);

      if (!matchingIDs.includes(option.id)) {
        matchingIDs.push(option.id);
      }
    }
  }

  return matchingIDs;
};

export const countSelected = (id: string, itemDictionary: SelectItemDictionary, onlyTopLevel = true) => {
  let count = 0;
  for (const child of itemDictionary[id].children) {
    if (itemDictionary[child].isSelected) {
      if (onlyTopLevel) {
        if (!itemDictionary[child].children.length) count++;
      } else {
        count++;
      }
    }
  }
  return count;
};

export const countAllSelected = (itemDictionary: SelectItemDictionary) =>
  Object.keys(itemDictionary)
    .filter((id) => itemDictionary[id].isSelected)
    .filter((id) => !itemDictionary[id]?.children.length).length;

export const countSelectedFromIDs = (selectedIDs: string[], items: ClassificationItem[]) => {
  let count = 0;

  const countSelected = (id: string, isParentSelected = false) => {
    const selected = selectedIDs.includes(id) || isParentSelected;
    const children = items.filter((i) => i.parentID === parseInt(id));

    if (children.length) {
      let count = 0;
      for (const child of children) {
        count += countSelected(`${child.id}`, selected);
      }
      return count;
    } else {
      return selected ? 1 : 0;
    }
  };

  for (const id of selectedIDs) {
    count += countSelected(id);
  }

  return count;
};

/**
 * Toggle the selected state of an item
 * - Checks for child items select state
 * - Checks for parent items select state
 */
export const toggleOption = (
  option: SelectOption,
  itemDictionary: SelectItemDictionary,
  checked: boolean | null = null,
  single: boolean = false
) => {
  const { id } = option;
  const isSelected = checked !== null ? checked : !itemDictionary[id].isSelected;

  const updatedItems = [];
  const updatedItemDictionary = JSON.parse(JSON.stringify(itemDictionary)) as SelectItemDictionary; // TODO: This is bad... but we really need a deep copy...

  // Toggle state of child options
  if (!single) {
    for (const child of itemDictionary[id].children) {
      if (itemDictionary[child].isSelected !== isSelected) {
        updatedItemDictionary[child].isSelected = isSelected;
        updatedItems.push({ ...itemDictionary[child], isSelected });
      }
    }
  }

  const item = { ...option };
  item.isSelected = isSelected;
  item.selectedChildCount = isSelected ? countSelected(id, updatedItemDictionary) : 0;
  updatedItemDictionary[id].isSelected = isSelected;
  updatedItemDictionary[id].selectedChildCount = item.selectedChildCount;
  updatedItems.push(item);

  // Check state of parent options
  const parents = [...item.parents].sort((a, b) => updatedItemDictionary[b].level - updatedItemDictionary[a].level);
  for (const parent of parents) {
    const selectedChildrenOfParent = countSelected(parent, updatedItemDictionary);
    const isParentSelected = updatedItemDictionary[parent].children.length === countSelected(parent, updatedItemDictionary, false);

    if (
      updatedItemDictionary[parent].selectedChildCount !== selectedChildrenOfParent ||
      updatedItemDictionary[parent].isSelected !== isParentSelected
    ) {
      updatedItemDictionary[parent].selectedChildCount = selectedChildrenOfParent;
      updatedItemDictionary[parent].isSelected = isParentSelected;
      updatedItems.push({ ...updatedItemDictionary[parent], selectedChildCount: selectedChildrenOfParent, isSelected: isParentSelected });
    }
  }

  // Check state of child options
  for (const child of updatedItemDictionary[id].children) {
    if (updatedItemDictionary[child].children.length) {
      const selectedChildCount = countSelected(child, updatedItemDictionary);
      if (updatedItemDictionary[child].selectedChildCount !== selectedChildCount) {
        updatedItemDictionary[child].selectedChildCount = selectedChildCount;
        updatedItems.push({ ...updatedItemDictionary[child], selectedChildCount });
      }
    }
  }

  // If in single mode, deselect everything exept for toggled item
  // TODO: This could be more elegant...
  if (single) {
    for (const id of Object.keys(itemDictionary)) {
      if (updatedItemDictionary[id].isSelected || updatedItemDictionary[id].selectedChildCount > 0) {
        const updatedIndex = updatedItems.findIndex((item) => item.id === id);
        const selectedChildCount = updatedItemDictionary[id].children.includes(option.id) ? 1 : 0;
        const isItemSelected = id === option.id;

        if (updatedIndex >= 0) {
          updatedItems[updatedIndex].isSelected = isItemSelected;
          updatedItems[updatedIndex].selectedChildCount = selectedChildCount;
        } else {
          updatedItems.push({ ...itemDictionary[id], isSelected: isItemSelected, selectedChildCount });
        }

        updatedItemDictionary[id].isSelected = isItemSelected;
      }
    }
  }

  return { isSelected, updatedItems, updatedItemDictionary };
};

export const toggleItemOpen = (option: SelectOption, itemDictionary: SelectItemDictionary) => {
  const updatedOption = { ...option };

  if (!updatedOption.isTouched) {
    updatedOption.isTouched = true;
  }

  const isOpen = !updatedOption.isOpen;
  updatedOption.isOpen = isOpen;

  const updatedItems = [updatedOption];
  for (const child of option.children) {
    const isDirectChild = itemDictionary[child].level === option.level + 1;
    updatedItems.push({ ...itemDictionary[child], isVisible: isDirectChild && isOpen, isOpen: false });
  }

  return updatedItems;
};

/**
 * Get a list of selected IDs
 * NOTE: Will return the parent ID if all children are selected, and the child IDs if only some are selected
 */
export const getSelectedIDs = (itemDictionary: SelectItemDictionary, alwaysReturnChildren: boolean = false) =>
  Object.keys(itemDictionary).filter((value) => {
    const item = itemDictionary[value];
    if (item.isSelected) {
      /** Do not return this ID if the direct parent has been selected */
      if (!alwaysReturnChildren && item.parents.length) {
        const directParent = itemDictionary[item.parents.find((parent) => itemDictionary[parent].level === item.level - 1)!];
        if (directParent.isSelected) return false;
      }

      /** Return this ID if all child elements are selected */
      if (item.children.length && item.children.length === item.selectedChildCount) {
        return true;
      }

      return true;
    }

    return false;
  }) as string[];

export const getOptionSelectedStates = (selectedIDs: string[], itemDictionary: SelectItemDictionary, single: boolean = false) => {
  const classificationState = convertSelectStateToClassificationState(itemDictionary);
  const verifiedSelectedIDs = validateSelectedClassificationIDs(
    selectedIDs.map((id) => parseInt(id)),
    classificationState
  ).map((id) => `${id}`);

  const classificationItems = Object.values(classificationState);

  const getSelectedState = (id: string) => {
    const isParentSelected = !!itemDictionary[id].parents.filter((parent) => verifiedSelectedIDs.includes(parent)).length;
    const isSelected = isParentSelected || verifiedSelectedIDs.includes(id);
    const selectedChildCount = countSelectedFromIDs(
      itemDictionary[id].children.filter((id) => verifiedSelectedIDs.includes(id)),
      classificationItems
    );

    return {
      isSelected,
      selectedChildCount,
    };
  };

  const selectedState = Object.keys(itemDictionary).reduce<{ [id: string]: { isSelected: boolean; selectedChildCount: number } }>(
    (list, value: string) => ({
      ...list,
      [value]: getSelectedState(value),
    }),
    {}
  );

  // If in single mode, deselect everything exept for the single selected item
  // TODO: This could be more elegant...
  if (single) {
    const [id] = selectedIDs;
    if (id) {
      for (const key of Object.keys(selectedState)) {
        selectedState[key].isSelected = itemDictionary[key].id === id;
        selectedState[key].selectedChildCount = itemDictionary[key].children.includes(id) ? 1 : 0;
      }
    }
  }

  return selectedState;
};

/** Helper to check if item is visible */
export const isItemVisible = (item: SelectOption, isSearching: boolean, isParentOpenable: boolean) => {
  if (!isParentOpenable && !isSearching) return true;
  if (!isSearching && item.isVisible) return true;
  if (isSearching && item.isMatching) return true;

  return false;
};

// Convert an item from classification state to select state (Recursive)
const convertItemToState = (item: ClassificationState[number], state: ClassificationState) => {
  const dto = (item: ClassificationState[number]): SelectState[number] => ({
    ...item,
    id: `${item.id}`,
    parents: item.parents.map((id) => `${id}`),
    children: item.children.map((id) => `${id}`),
    level: item.parents.length,
    isOpen: false,
    isSelected: false,
    selectedChildCount: 0,
    isFocused: false,
    isVisible: !item.parents.length,
    isMatching: true,
    isTouched: false,
    index: 0,
  });

  const items = [dto(item)];
  for (const child of item.children) {
    if (state[child].parents.length === item.parents.length + 1) {
      items.push(...convertItemToState(state[child], state));
    }
  }

  return items;
};

/** Convert "items" style data strcuture to a selection friendly linked list */
export const convertItemsToState = (items: SelectItemProps[], locale: string): SelectState => {
  const classificationState = convertClassificationItemsToState(items, locale);

  const stateItems = [];
  for (const item of Object.values(classificationState) as ClassificationStateItem[]) {
    if (!item.parents.length) {
      stateItems.push(...convertItemToState(item, classificationState));
    }
  }

  // Save the position of this item in the sorted list
  for (let i = 0; i < stateItems.length; i++) {
    stateItems[i].index = i;
  }

  // Convert to a dictionary
  const itemDictionary = stateItems.reduce((dictionary, item) => ({ ...dictionary, [item.id]: item }), {});

  return itemDictionary;
};
