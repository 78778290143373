import { ChangeEvent, useCallback } from 'react';
import { UseStore, SetState, State } from 'zustand';
import shallow from 'zustand/shallow';
import pick from 'lodash/pick';

/**
 * Helper for Zustand to allow for easy state scoping
 * @param store The Zustand store to get state from
 * @param scope An array of keys that sould cause a re-render when changed
 * @returns The entire state object
 * @example useScopedState(state, ['foo', 'bar']) // If state is { foo, bar, x, y }, then this component will update only if foo or bar change
 */
export const useScopedState = <T extends State>(store: UseStore<T>, scope: readonly (keyof T)[] = null) => {
  return store(
    useCallback((state) => state, []),
    (a, b) => {
      // If scope is set to "null", always update
      if (scope === null) return false;

      // If scope is set to an empty array, never update
      if (!scope.length) return true;

      // If scope is used, only update when items on the scope have changed
      return shallow(pick(a, scope), pick(b, scope));
    }
  );
};

/**
 * Helper for Zustand to make attaching inputs to state easy
 * @param store The Zustand store to get state from
 * @param field A key of the state object to assign to this field
 * @returns { value, onChange }
 * @example const nameField = useFieldState(state, 'name');
 * return (<input {...nameField} />);
 */
export const useFieldState = <T extends State & { set: SetState<State> }>(store: UseStore<T>, field: keyof T) => {
  const state = useScopedState(store, [field, 'set']);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => state.set({ [field]: event.target.value });

  return { value: state[field] as any, onChange };
};
