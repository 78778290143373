import React, { FC } from 'react';
import { Banner } from './Banner';

export interface ErrorProps {
  error?: string | JSX.Element;
}

/**
 * Helper to display errors easily
 *
 * If given error value is null, displays nothing.
 * If it is a string or JSX element, displays that inside an error banner instead.
 */
export const Error: FC<ErrorProps> = ({ error = null }) => {
  if (!error) return null;
  return <Banner type="error">{error}</Banner>;
};
