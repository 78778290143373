import create, { GetState, SetState, UseStore } from 'zustand';
import { combine } from 'zustand/middleware';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import fuzzysort from 'fuzzysort';
import type { WWWList, WWWListItem, WWWListGroup } from '../common/documents/WWWList';
import type { WWWProjectMenuItem } from '../common/documents/WWWProject';
import { ListType } from '../common/Constants';
import { nextApiRequest } from '../lib/utils';
import { gaEvent } from '../lib/ga';
import { addListContactPerson } from '../lib/listContactPerson';
import { loginContext } from './loginContext';

export type LuotettavaKumppaniResponseState = 'Ok' | 'Huomioi' | 'Tietoja odotetaan' | 'Selvitä' | 'Seis' | 'not found' | 'error';

export enum LuotettavaKumppaniState {
  OK = 1,
  NOTICE = 2,
  WAITING = 3,
  INVESTIGATE = 4,
  STOP = 5,
  NOT_FOUND = 6,
  ERROR = 7,
}

export const listItemFields = ['listItemID', 'isInvited', 'listContactPersons', 'pendingContactPersonInvites', 'comments'] as const;

export type ListsCompany = Omit<WWWListItem, (typeof listItemFields)[number]>;
export type ListsListItem = Pick<WWWListItem, (typeof listItemFields)[number] | 'companyID'>;

type CommonListValues = Omit<ListsState, 'contactPersonEmailDialog' | 'createProjectDialog' | 'projectsMenuItems'>;

type CommonListState = CommonListValues & ReturnType<typeof commonListActions>;

export interface ListStoreConsumerProps {
  store: UseStore<CommonListState>;
}

export type ListsState = {
  selectedListID: number | null;
  isRemoveItemPending: boolean;

  lists: WWWList[];
  listGroups: WWWListGroup[];
  searchedLists: WWWList[];
  searchedListIDs: number[];
  searchedListGroupIDs: number[];
  listMap: Map<number, WWWList>;
  listGroupMap: Map<number, WWWListGroup>;
  listGroupItemMap: Map<number, WWWList[]>;

  /** Project entries for the list picker menu */
  projectsMenuItems: WWWProjectMenuItem[];

  companyMap: Map<number, ListsCompany>;
  listItemMap: Map<number, ListsListItem>;

  /** Index used to quickly search lists */
  searchTargets: { listID: number; label: Fuzzysort.Prepared }[];

  /** List groups that are open in the sidebar */
  openListGroupIDs: number[];

  editDialog: { list: WWWList; copyListID?: number } | null;
  editGroupDialog: { listGroup: WWWListGroup } | null;
  createProjectDialog: true | null;
  contactPersonMenu: { anchor: Element; listItemID: number } | null;
  contactPersonDialog: { companyID: number; listContactPersonID: number } | null;
  contactPersonEmailDialog: { listID: number; company: WWWListItem } | null;
  commentMenu: { anchor: Element; listItem: ListsListItem } | null;

  /** Used to filter lists by operationalAreas */
  filterByOperationalAreaIDs: string[];
  isOperationalAreasFilterDialogOpen: boolean;

  /** Company ID mapped to luotettava kumppani status */
  luotettavaKumppaniMap: Map<number, LuotettavaKumppaniState>;
  isLuotettavaKumppaniLoading: boolean;
};

const listsStateDefaults: ListsState = {
  selectedListID: null,
  isRemoveItemPending: false,

  lists: [],
  listGroups: [],
  searchedLists: [],
  searchedListIDs: [],
  searchedListGroupIDs: [],
  listMap: new Map(),
  listItemMap: new Map(),
  listGroupMap: new Map(),
  listGroupItemMap: new Map(),

  projectsMenuItems: [],

  companyMap: new Map(),

  searchTargets: [],

  openListGroupIDs: [],

  editDialog: null,
  editGroupDialog: null,
  createProjectDialog: null,
  contactPersonMenu: null,
  contactPersonDialog: null,
  contactPersonEmailDialog: null,
  commentMenu: null,

  filterByOperationalAreaIDs: [],
  isOperationalAreasFilterDialogOpen: false,

  luotettavaKumppaniMap: new Map(),
  isLuotettavaKumppaniLoading: false,
};

const getCompanyFromListByID = (listID: number, companyID: number, get: GetState<CommonListValues>) => {
  const { listMap } = get();

  const list = listMap.get(listID);
  if (!list) return null;

  const company = list.items.find((item) => item.companyID === companyID);
  if (!company) return null;

  return company;
};

export type StateAction<T = {}, D = { status: string; error?: string | JSX.Element }> = (
  set: SetState<CommonListValues>,
  get: GetState<CommonListValues>,
  stateID: string
) => (data?: T) => Promise<D>;

const convertListsToState = (
  stateID: string,
  selectedListID: number | null,
  lists: WWWList[],
  listGroups: WWWListGroup[],
  projectsMenuItems: WWWProjectMenuItem[]
): any => {
  const listMap = new Map<number, WWWList>();
  const listGroupMap = new Map<number, WWWListGroup>();
  const listGroupItemMap = new Map<number, WWWList[]>(listGroups.map((item) => [item.listGroupID, []]));

  const companyMap = new Map<number, ListsCompany>();
  const listItemMap = new Map<number, ListsListItem>();

  let searchTargets: { listID: number; label: Fuzzysort.Prepared }[] = [];

  for (const listGroup of listGroups) {
    listGroupMap.set(listGroup.listGroupID, listGroup);
  }

  for (const list of lists) {
    listMap.set(list.listID, list);
    if (list.listTypeID === ListType.COMPANYLIST) {
      searchTargets.push({ listID: list.listID, label: fuzzysort.prepare(list.label) });
    }

    if (list.listGroupID && list.listTypeID === ListType.COMPANYLIST) {
      listGroupItemMap.get(list.listGroupID)?.push(list);
    }

    for (const item of list.items) {
      listItemMap.set(item.listItemID, pick(item, [...listItemFields, 'companyID']));
      companyMap.set(item.companyID, omit(item, listItemFields));
    }
  }

  const { searchedLists: _searchedLists } = listsState.getState();
  const searchedLists = _searchedLists.map((item) => listMap.get(item.listID)).filter((item) => !!item);
  const searchedListIDs = searchedLists.map((item) => item.listID);
  const searchedListGroupIDs = searchedLists.map((item) => item.listGroupID);

  // Update cache
  if (stateID === 'lists') {
    const userID = loginContext.get().userID;
    if (userID) {
      localStorage.setItem(`constle.${stateID}`, JSON.stringify({ userID, lists, listGroups, projectsMenuItems, timestamp: Date.now() }));
    }
  }

  const result = {
    lists,
    listGroups,
    searchedLists,
    listMap,
    listItemMap,
    listGroupMap,
    listGroupItemMap,
    searchTargets,
    searchedListIDs,
    searchedListGroupIDs,
  };

  // Always use listsState to update "projectsMenuItems"
  if (stateID === 'lists') {
    (result as any).projectsMenuItems = projectsMenuItems;
  } else {
    listsState.getState().doUpdateProjects({ projectsMenuItems });
  }

  if (companyMap) return { ...result, companyMap };

  return result;
};

const doLoad: StateAction = (set, get, stateID) => async () => {
  const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/lists');

  // Redirect to login in component
  if (status === 'unauthorized') return { status };

  if (status !== 'ok') {
    // FIXME: Handle error
    return;
  }

  const selectedListID = get().selectedListID || lists[0]?.listID;

  set({ selectedListID, ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

  return { status: 'ok' };
};

const doLoadCached =
  (set: SetState<ListsState>, get: GetState<ListsState>) =>
  ({ userID }) => {
    const localStorageKey = 'constle.lists';

    const purge = () => {
      listsState.getState().doLoad();
      localStorage.removeItem(localStorageKey);
    };

    try {
      const cached = localStorage.getItem(localStorageKey);
      if (!cached) return purge();

      const { userID: cacheUserID, lists, listGroups = null, projectsMenuItems = null, timestamp } = JSON.parse(cached);
      if (userID !== cacheUserID) return purge();

      // Precaution for listGroups update
      if (!listGroups) return purge();
      if (!projectsMenuItems) return purge();

      if ((Date.now() - timestamp) / 1000 > 60 * 60) return purge();

      listsState.getState().doUpdate({ lists, listGroups, projectsMenuItems });
    } catch (error) {
      console.log(error);
      return purge();
    }
  };

const doUpdate: StateAction<{ lists: WWWList[]; listGroups: WWWListGroup[]; projectsMenuItems: WWWProjectMenuItem[] }> =
  (set, get, stateID) =>
  async ({ lists, listGroups, projectsMenuItems }) => {
    const { selectedListID } = get();
    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });
    return { status: 'ok' };
  };

const doUpdateProjects =
  (set: SetState<ListsState>, get: GetState<ListsState>) =>
  async ({ projectsMenuItems }) => {
    const { selectedListID, lists, listGroups } = get();
    set({ ...convertListsToState('lists', selectedListID, lists, listGroups, projectsMenuItems) });
    return { status: 'ok' };
  };

const doSearch: StateAction<{ search: string }> =
  (set, get) =>
  async ({ search }) => {
    const { listMap, searchTargets } = get();

    if (!search) {
      set({ searchedLists: [] });
      return { status: 'ok' };
    }

    const results = fuzzysort.go(search, searchTargets, { key: 'label' });
    const searchedLists = results.map((item) => listMap.get(item.obj.listID)).filter((item) => !!item);
    const searchedListIDs = searchedLists.map((item) => item.listID);
    const searchedListGroupIDs = searchedLists.map((item) => item.listGroupID);

    set({ searchedLists, searchedListIDs, searchedListGroupIDs });

    return { status: 'ok' };
  };

const doOpenAddDialog: StateAction = (set, get) => async () => {
  set({ editDialog: { list: { listID: 0, listGroupID: 0, listTypeID: ListType.COMPANYLIST, label: '', items: [] } } });
  return { status: 'ok' };
};

const doAdd: StateAction<{ listGroupID: number; listTypeID: ListType; projectID?: number; label: string }> =
  (set, get, stateID) =>
  async ({ listGroupID, listTypeID, projectID = null, label }) => {
    gaEvent('add_list', { event_category: 'engagement', event_label: 'add' });

    const { status, lists, listGroups, projectsMenuItems, listID } = await nextApiRequest('/api/v1/www/list/add', {
      listGroupID,
      listTypeID,
      projectID,
      label,
    });

    if (status !== 'ok') return { status };

    set({ selectedListID: listID, ...convertListsToState(stateID, listID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doSelect: StateAction<{ listID: number }> =
  (set, get) =>
  async ({ listID }) => {
    const { listMap } = get();

    const list = listMap.get(listID);
    if (!list) return;

    const companyMap = new Map();
    for (const company of list.items) {
      companyMap.set(company.companyID, company);
    }

    set({ selectedListID: listID, companyMap });

    return { status: 'ok' };
  };

const doOpenEditDialog: StateAction = (set, get) => async () => {
  const { selectedListID, listMap } = get();
  if (!selectedListID) return;

  const list = listMap.get(selectedListID);
  if (!list) return;

  set({ editDialog: { list } });

  return { status: 'ok' };
};

const doEdit: StateAction<{ listID: number; listGroupID: number; listTypeID: ListType; projectID?: number; label: string }> =
  (set, get, stateID) =>
  async ({ listID, listGroupID, listTypeID, projectID = null, label }) => {
    gaEvent('edit_list', { event_category: 'engagement', event_label: 'edit' });

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/list/edit', {
      listID,
      listGroupID,
      listTypeID,
      projectID,
      label,
    });

    if (status !== 'ok') return { status };

    const { selectedListID } = get();

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doOpenCopyDialog: StateAction = (set, get) => async () => {
  const { selectedListID, listMap } = get();
  if (!selectedListID) return;

  const list = listMap.get(selectedListID);
  if (!list) return;

  set({ editDialog: { list: { ...list, listID: 0 }, copyListID: selectedListID } });

  return { status: 'ok' };
};

const doCopy: StateAction<{ listID: number; listGroupID: number; listTypeID: ListType; projectID?: number; label: string }> =
  (set, get, stateID) =>
  async ({ listID: copyListID, listGroupID, listTypeID, projectID = null, label }) => {
    gaEvent('add_list', { event_category: 'engagement', event_label: 'add' });

    const { status, lists, listGroups, projectsMenuItems, listID } = await nextApiRequest('/api/v1/www/list/copy', {
      listID: copyListID,
      listGroupID,
      listTypeID,
      projectID,
      label,
    });

    if (status !== 'ok') return { status };

    set({ selectedListID: listID, ...convertListsToState(stateID, listID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doRemove: StateAction<{ listID: number; projectID?: number }> =
  (set, get, stateID) =>
  async ({ listID, projectID = null }) => {
    if (!confirm('Sure?')) return; // TODO: better confirmation and some info

    gaEvent('remove_list', { event_category: 'engagement', event_label: 'remove' });

    const { lists: currentLists } = get();

    const listIndex = currentLists.findIndex((list) => list.listID === listID);

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/list/remove', {
      listID,
      projectID,
    });

    if (status !== 'ok') {
      // FIXME: Handle error
      return;
    }

    // Select the previous list in the sidebar (if possbile)
    const selectedListID = listIndex > 0 ? listIndex : lists[0]?.listID ?? null;

    set({ selectedListID, ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doOpenAddGroupDialog: StateAction = (set, get) => async () => {
  set({ editGroupDialog: { listGroup: { listGroupID: 0, label: '' } } });
  return { status: 'ok' };
};

const doOpenEditGroupDialog: StateAction<{ listGroupID: number }> =
  (set, get) =>
  async ({ listGroupID }) => {
    const { listGroups } = get();
    const listGroup = listGroups.find((item) => item.listGroupID === listGroupID);
    if (!listGroup) return { status: 'not found' };

    set({ editGroupDialog: { listGroup: { ...listGroup } } });

    return { status: 'ok' };
  };

const doAddGroup: StateAction<{ projectID?: number; label: string }> =
  (set, get, stateID) =>
  async ({ projectID = null, label }) => {
    gaEvent('add_list_group', { event_category: 'engagement', event_label: 'add group' });

    const { status, lists, listGroups, projectsMenuItems, listGroupID } = await nextApiRequest('/api/v1/www/listgroup/add', {
      projectID,
      label,
    });

    if (status !== 'ok') return { status };

    const { selectedListID } = get();

    console.log({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doEditGroup: StateAction<{ listGroupID: number; projectID?: number; label: string }> =
  (set, get, stateID) =>
  async ({ listGroupID, projectID = null, label }) => {
    gaEvent('edit_list_group', { event_category: 'engagement', event_label: 'edit group' });

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/listgroup/edit', {
      listGroupID,
      projectID,
      label,
    });

    if (status !== 'ok') return { status };

    const { selectedListID } = get();

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doRemoveGroup: StateAction<{ listGroupID: number; projectID?: number }> =
  (set, get, stateID) =>
  async ({ listGroupID, projectID = null }) => {
    if (!confirm('Oletko varma?')) return; // TODO: better confirmation and some info

    gaEvent('remove_list_group', { event_category: 'engagement', event_label: 'remove group' });

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/listgroup/remove', {
      listGroupID,
      projectID,
    });

    if (status !== 'ok') {
      // FIXME: Handle error
      return;
    }

    const { selectedListID } = get();

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doAddItem: StateAction<{ listID: number; companyID: number }> =
  (set, get, stateID) =>
  async ({ listID, companyID }) => {
    gaEvent('add_list_item', { event_category: 'engagement', event_label: 'add list item' });

    const { selectedListID } = get();

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/list/item-add', {
      listID,
      companyID,
    });

    if (status !== 'ok') {
      // FIXME: Handle error
      return { status };
    }

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doRemoveItem: StateAction<{ listID: number; companyID: number; projectID?: number; pendingOverride?: boolean }> =
  (set, get, stateID) =>
  async ({ listID, companyID, projectID = null, pendingOverride = false }) => {
    gaEvent('remove_list_item', { event_category: 'engagement', event_label: 'remove list item' });

    const { selectedListID, isRemoveItemPending, lists: originalLists, listMap } = get();

    if (isRemoveItemPending && !pendingOverride) return { status: 'loading' };

    const list = listMap.get(listID);
    // if (!list) return { status: 'invalid list' };

    // For UX, remove the list element right away. If something goes wrong, bring it back afterwards.
    if (list) {
      const updatedList = { ...list, items: list.items.filter((item) => item.companyID !== companyID) };
      listMap.set(listID, updatedList);
      set({ isRemoveItemPending: true, listMap: new Map(listMap) });
    }

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/list/item-remove', {
      listID,
      companyID,
      projectID,
    });

    set({ isRemoveItemPending: false });

    if (status !== 'ok') {
      // FIXME: Handle error
      set({ ...convertListsToState(stateID, selectedListID, originalLists, listGroups, projectsMenuItems) });
      return { status };
    }

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doOpenContactPersonMenu: StateAction<{ anchor: Element; listItemID: number }> = (set, get) => async (contactPersonMenu) => {
  set({ contactPersonMenu });
  return { status: 'ok' };
};

const doOpenContactPersonDialog: StateAction<{ companyID: number; listContactPersonID: number }> =
  (set, get) => async (contactPersonDialog) => {
    set({ contactPersonDialog });
    return { status: 'ok' };
  };

const doOpenContactPersonEmailDialog =
  (set: SetState<ListsState>, get: GetState<ListsState>) =>
  async ({ companyID }) => {
    const { selectedListID } = get();

    const company = getCompanyFromListByID(selectedListID, companyID, get);
    if (!company) return null;

    set({ contactPersonEmailDialog: { listID: selectedListID, company } });

    return { status: 'ok' };
  };

const doOpenCommentMenu: StateAction<{ anchor: Element; listItemID: number }> =
  (set, get) =>
  async ({ anchor, listItemID }) => {
    const { listItemMap } = get();

    const listItem = listItemMap.get(listItemID);
    if (!listItem) return null;

    set({ commentMenu: { anchor, listItem } });

    return { status: 'ok' };
  };

const doGetListContactPerson =
  (set: SetState<CommonListValues>, get: GetState<CommonListValues>, stateID: string) =>
  ({ companyID, listContactPersonID }) => {
    if (!companyID || !listContactPersonID) return null;

    const { selectedListID } = get();

    const company = getCompanyFromListByID(selectedListID, companyID, get);
    if (!company) return null;

    return company.listContactPersons.find((item) => item.listContactPersonID === listContactPersonID) || null;
  };

const doAddCompanyContactPerson: StateAction<{ projectID?: number; contactPersonID: number }> =
  (set, get, stateID) =>
  async ({ projectID = null, contactPersonID }) => {
    gaEvent('add_contact_person_company', { event_category: 'engagement', event_label: 'add contact person from company' });

    const { selectedListID, listItemMap, contactPersonMenu } = get();
    const listItem = listItemMap.get(contactPersonMenu.listItemID);

    if (!selectedListID || !contactPersonMenu || !listItem) return;

    const { status, lists, listGroups, projectsMenuItems } = await addListContactPerson({
      listID: selectedListID,
      companyID: listItem.companyID,
      projectID,
      contactPersonID,
      isInListView: true,
    });

    if (status !== 'ok') {
      // FIXME: Handle error
      return;
    }

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doInviteRegistered: StateAction<{ companyID: number }> =
  (set, get, stateID) =>
  async ({ companyID }) => {
    gaEvent('update_list_company_invite_registered', {
      event_category: 'engagement',
      event_label: 'update list company invite registered',
    });

    const { selectedListID } = get();

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/list/item-contact-person-invite', {
      listID: selectedListID,
      companyID,
    });

    if (status !== 'ok') {
      // FIXME: Handle error
      return;
    }

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doCancelInvite: StateAction<{ companyID: number }> =
  (set, get, stateID) =>
  async ({ companyID }) => {
    gaEvent('list_company_cancel_invite', {
      event_category: 'engagement',
      event_label: 'list company cancel invite',
    });

    const { selectedListID } = get();

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/list/item-contact-person-cancel-invite', {
      listID: selectedListID,
      companyID,
    });

    if (status !== 'ok') {
      // FIXME: Handle error
      return;
    }

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    return { status: 'ok' };
  };

const doUpdateComment: StateAction<{
  listItemCommentID?: number;
  listItemID: number;
  projectID?: number;
  comment: string | null;
  isDeleted?: boolean;
}> =
  (set, get, stateID) =>
  async ({ listItemCommentID, listItemID, projectID = null, comment, isDeleted = false }) => {
    gaEvent('update_list_company_comment', { event_category: 'engagement', event_label: 'update list company comment' });

    const { status, lists, listGroups, projectsMenuItems } = await nextApiRequest('/api/v1/www/list/item-comment-update', {
      listItemCommentID,
      listItemID,
      projectID,
      comment,
      isDeleted,
    });

    if (status !== 'ok') {
      // FIXME: Handle error
      return;
    }

    const { selectedListID, commentMenu } = get();

    set({ ...convertListsToState(stateID, selectedListID, lists, listGroups, projectsMenuItems) });

    if (commentMenu) doOpenCommentMenu(set, get, stateID)({ anchor: commentMenu.anchor, listItemID });

    return { status: 'ok' };
  };

const luotettavaKumppaniResponseStateMap: Record<LuotettavaKumppaniResponseState, LuotettavaKumppaniState> = {
  Ok: LuotettavaKumppaniState.OK,
  Huomioi: LuotettavaKumppaniState.NOTICE,
  'Tietoja odotetaan': LuotettavaKumppaniState.WAITING,
  Selvitä: LuotettavaKumppaniState.INVESTIGATE,
  Seis: LuotettavaKumppaniState.STOP,
  'not found': LuotettavaKumppaniState.NOT_FOUND,
  error: LuotettavaKumppaniState.ERROR,
} as const;

const fetchLuotettavakumppaniForBusinessID = async (businessID: string) => {
  const { status, tilaajavastuu } = await nextApiRequest('/api/v1/company/tilaajavastuu', { businessID });

  let luotettavakumppaniStatus: LuotettavaKumppaniResponseState = 'error';
  if (status !== 'ok' || !tilaajavastuu?.status) {
    luotettavakumppaniStatus = 'error';
  } else {
    luotettavakumppaniStatus = tilaajavastuu.status;
  }

  return luotettavaKumppaniResponseStateMap[luotettavakumppaniStatus];
};

const doLoadLuotettavaKumppani: StateAction<{ listID: number }> =
  (set, get) =>
  async ({ listID }) => {
    gaEvent('luotettava_kumppani_list', { event_category: 'engagement', event_label: 'load luotettava kumppani' });

    const { listMap, luotettavaKumppaniMap, isLuotettavaKumppaniLoading } = get();
    if (isLuotettavaKumppaniLoading) return;

    set({ isLuotettavaKumppaniLoading: true });

    const list = listMap.get(listID);
    if (!list) return;

    const companies = list.items;

    for (const company of companies) {
      const luotettavaKumppaniStatus = await fetchLuotettavakumppaniForBusinessID(company.businessID);
      luotettavaKumppaniMap.set(company.companyID, luotettavaKumppaniStatus);
      set({ luotettavaKumppaniMap });
    }

    set({ isLuotettavaKumppaniLoading: false });

    return { status: 'ok' };
  };

const doToggleListGroup: StateAction<{ listGroupID: number }> =
  (set, get) =>
  async ({ listGroupID }) => {
    const { openListGroupIDs } = get();
    if (openListGroupIDs.includes(listGroupID)) {
      set({ openListGroupIDs: openListGroupIDs.filter((id) => id !== listGroupID) });
    } else {
      set({ openListGroupIDs: [...openListGroupIDs, listGroupID] });
    }
    return { status: 'ok' };
  };

const doGetContactPersonEmailsFromList =
  (set: SetState<CommonListValues>, get: GetState<CommonListValues>, stateID: string) =>
  ({ listID }) => {
    const { listMap } = get();

    const list = listMap.get(listID);
    if (!list) return [];

    const contactPersonEmails = list.items
      .map((item) => item.listContactPersons.map((contactPerson) => contactPerson.email || contactPerson.contactPerson?.email))
      .flat()
      .filter((email) => !!email)
      .map((email) => email.replace(';', ''));

    return Array.from(new Set(contactPersonEmails));
  };

export const commonListActions = (set: SetState<CommonListValues>, get: GetState<CommonListValues>, stateID: string) => ({
  /** State methods */
  doUpdate: doUpdate(set, get, stateID),
  doSearch: doSearch(set, get, stateID),
  doSelect: doSelect(set, get, stateID),
  doOpenAddDialog: doOpenAddDialog(set, get, stateID),
  doOpenAddGroupDialog: doOpenAddGroupDialog(set, get, stateID),
  doOpenEditGroupDialog: doOpenEditGroupDialog(set, get, stateID),
  doAdd: doAdd(set, get, stateID),
  doOpenEditDialog: doOpenEditDialog(set, get, stateID),
  doEdit: doEdit(set, get, stateID),
  doOpenCopyDialog: doOpenCopyDialog(set, get, stateID),
  doCopy: doCopy(set, get, stateID),
  doRemove: doRemove(set, get, stateID),
  doAddGroup: doAddGroup(set, get, stateID),
  doEditGroup: doEditGroup(set, get, stateID),
  doRemoveGroup: doRemoveGroup(set, get, stateID),
  doAddItem: doAddItem(set, get, stateID),
  doRemoveItem: doRemoveItem(set, get, stateID),
  doOpenContactPersonMenu: doOpenContactPersonMenu(set, get, stateID),
  doOpenContactPersonDialog: doOpenContactPersonDialog(set, get, stateID),
  doOpenCommentMenu: doOpenCommentMenu(set, get, stateID),
  doGetListContactPerson: doGetListContactPerson(set, get, stateID),
  doAddCompanyContactPerson: doAddCompanyContactPerson(set, get, stateID),
  doUpdateComment: doUpdateComment(set, get, stateID),
  doLoadLuotettavaKumppani: doLoadLuotettavaKumppani(set, get, stateID),
  doToggleListGroup: doToggleListGroup(set, get, stateID),
  doGetContactPersonEmailsFromList: doGetContactPersonEmailsFromList(set, get, stateID),

  /** General set state */
  set,
});

export const listsState = create(
  combine(listsStateDefaults, (set, get) => ({
    doUpdateProjects: doUpdateProjects(set, get),
    doLoad: doLoad(set, get, 'lists'),
    doLoadCached: doLoadCached(set, get),
    doOpenContactPersonEmailDialog: doOpenContactPersonEmailDialog(set, get),
    doInviteRegistered: doInviteRegistered(set, get, 'lists'),
    doCancelInvite: doCancelInvite(set, get, 'lists'),
    ...commonListActions(set, get, 'lists'),
    set,
  }))
);
