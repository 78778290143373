import { useEffect, useState } from 'react';
import { breakPoint as breakPointMap } from '../components/Theme';

export type BreakPoint = keyof typeof breakPointMap;

const getBreakPointStatus = (breakPoint: BreakPoint) => {
  // Get the width of the browser viewport, if in SSR, use a sane default
  const viewWidth = typeof window === 'undefined' ? 1920 : Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const breakPointWidth = breakPointMap[breakPoint];

  if (breakPoint === 'large') return viewWidth > breakPointMap['medium'];
  return viewWidth <= breakPointWidth;
};

/** Monitor the status of a break point, if viewport is smaller than said breakpoint, returns true */
export const useBreakPoint = (breakPoint: BreakPoint, initialState = false) => {
  const [isBreakPointActive, setBreakPointActive] = useState(initialState);

  // Get initial break point status, has to be in useEffect due to SSR
  useEffect(() => {
    setBreakPointActive(getBreakPointStatus(breakPoint));
  });

  useEffect(() => {
    // Do not do anything if we are in SSR
    if (typeof window === 'undefined') return;

    const onWindowResize = () => {
      const breakPointStatus = getBreakPointStatus(breakPoint);
      setBreakPointActive(breakPointStatus);
    };

    window.addEventListener('resize', onWindowResize, true);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [breakPoint]);

  return isBreakPointActive;
};
