import { FC } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Color } from '../components/Theme';

const StyledButton = styled(MuiButton)`
  margin-left: 0.5rem !important;
  border-radius: 50px !important;

  &.MuiButton-containedPrimary {
    color: ${Color.white};
  }

  .MuiCircularProgress-root {
    margin-left: 5px;
    margin-right: -5px;

    svg {
      color: ${Color.white};
    }
  }
`;

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({ endIcon = null, className, loading, disabled, href, children, ...other }) => {
  const button = (
    <StyledButton
      variant="contained"
      color="primary"
      size="large"
      className={`${className} ${loading ? 'loading' : ''}`}
      disableElevation
      disabled={disabled ?? loading}
      endIcon={loading ? <CircularProgress size={20} thickness={4.5} /> : endIcon}
      href={href}
      {...other}
    >
      {children}
    </StyledButton>
  );

  if (href) {
    return <Link href={href}>{button}</Link>;
  }

  return button;
};
