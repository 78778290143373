/** Project specific constants */

// NOTE! Don't use const enums, they aren't iterable

export enum UserGroup {
  /**  Constle company user */
  NORMAL = 1,
  /**  Pro user */
  ADMIN = 2,
}

export enum UserState {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum FileType {
  UNKNOWN = 0,
  IMAGE = 1,
}

/**
 * This was imported from another project along with image upload.
 * Might not be that useful here, but the FrameworkFile table uses it so we kinda need it...
 * The idea is to link files to certain IDs, like user or company, with modelID.
 * But you can't tell to which table the ID belongs to, unless you have some other ID that links it to a table also.
 * This is that other ID.
 */
export enum ModelType {
  COMPANY = 1,
}

export const SearchPageResultCount = 50;

export const SearchCacheKey = 'search.cache';
export const SearchCacheEntryTTL = 30 * 60;

export enum RegisterCompanySessionState {
  PENDING_AUTHENTICATION = 1, // Strong auth or auth form pending
  PENDING_COMPANY = 2, // Company ID or form pending
  PENDING_VALIDATION = 3, // All required data is present, validation pending
  PENDING_REVIEW = 4, // Constle manual review pending
  ACCEPTED = 5, // Automatic auth & company accepted, or manual Constle review accepted
  REJECTED = 6,
}

export const RegisterCompanySessionStateTexts: { [key: number]: string } = {
  [RegisterCompanySessionState.PENDING_AUTHENTICATION]: 'Pending authentication',
  [RegisterCompanySessionState.PENDING_COMPANY]: 'Pending Company',
  [RegisterCompanySessionState.PENDING_VALIDATION]: 'Pending Validation',
  [RegisterCompanySessionState.PENDING_REVIEW]: 'Pending Review',
  [RegisterCompanySessionState.ACCEPTED]: 'Accepted',
  [RegisterCompanySessionState.REJECTED]: 'Rejected',
};

export enum RegisterCompanyTeliaState {
  PENDING = 1, // Strong auth pending
  ACCEPTED = 2, // Strong auth accepted
  REJECTED = 3,
}

export const RegisterCompanyTeliaTimeout = 5 * 60;
export const RegisterCompanySessionTimeout = 60 * 60;

export enum RegisterCompanySessionType {
  TELIA = 1,
  MANUAL = 2,
}

export enum RegisterCompanyCompanyType {
  EXISTING = 1,
  MANUAL = 2,
}

export enum State {
  OPEN = 1,
  OFFERED = 2,
  SOLD = 3,
  PLANNED = 4,
  DELIVERED = 5,
  LOST = 6,
  DRAFT = 7,
}

export enum AccountUserAccessRole {
  NORMAL = 1,
  ADMIN = 2, // Access to company user admin
}

export const AccountUserAccessRoleTexts = {
  [AccountUserAccessRole.NORMAL]: 'Normal',
  [AccountUserAccessRole.ADMIN]: 'Administrator',
};

export enum AccountPremiumLevel {
  UNKNOWN = 0,
  FREE = 1,
  PREMIUM = 2,
}

export const AccountPremiumLevelTexts = {
  [AccountPremiumLevel.UNKNOWN]: '',
  [AccountPremiumLevel.FREE]: 'Basic',
  [AccountPremiumLevel.PREMIUM]: 'Pro',
};

export enum PremiumRevenueBracketID {
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 3,
  GIANT = 4,
}

export enum CompanyFlag {
  IS_VASTUUGROUP = 1,
  IS_TAITOREKISTERI = 2,
  IS_VALTTIKORTTI = 3,
  IS_LUOTETTAVAKUMPPANI = 4,
}

export const CompanyFlagTexts = {
  [CompanyFlag.IS_VASTUUGROUP]: 'VastuuGroup',
  [CompanyFlag.IS_VALTTIKORTTI]: 'Valttikortti',
  [CompanyFlag.IS_TAITOREKISTERI]: 'Taitorekisteri',
  [CompanyFlag.IS_LUOTETTAVAKUMPPANI]: 'Luotettava kumppani',
};

export const CompanyFlagList = [
  CompanyFlag.IS_VASTUUGROUP,
  CompanyFlag.IS_TAITOREKISTERI,
  CompanyFlag.IS_VALTTIKORTTI,
  CompanyFlag.IS_LUOTETTAVAKUMPPANI,
];

// Exact turnover is viewed if accounting period data is no older than 2 years old, safe is 3 years
export const TURNOVER_EXPIRE_LIMIT_MS = 1000 * 60 * 60 * 24 * 366 * 3;

/** Max revenues for PremiumRevenueBracketIDs */
export const PremiumRevenueBracketTurnoverLimits = {
  [PremiumRevenueBracketID.SMALL]: 5 * 1000000,
  [PremiumRevenueBracketID.MEDIUM]: 20 * 1000000,
  [PremiumRevenueBracketID.LARGE]: 100 * 1000000,
  [PremiumRevenueBracketID.GIANT]: Number.POSITIVE_INFINITY,
};

export const PremiumRevenueBracketIDTexts = {
  [PremiumRevenueBracketID.SMALL]: '0 - 5M',
  [PremiumRevenueBracketID.MEDIUM]: '5 - 20M',
  [PremiumRevenueBracketID.LARGE]: '20M - 100M',
  [PremiumRevenueBracketID.GIANT]: '> 100M',
};

export enum PremiumBusinessSectorID {
  CONSTRUCTION = 1,
  PRIVATE_ENTREPRENEUR = 2,
  HOSTING = 3,
  MUNICIPALITY = 4,
  CONSULT = 5,
  OTHER = 10,
}

export const PremiumBusinessSectorIDTexts = {
  [PremiumBusinessSectorID.CONSTRUCTION]: 'Construction',
  [PremiumBusinessSectorID.PRIVATE_ENTREPRENEUR]: 'Private entrepreneur',
  [PremiumBusinessSectorID.HOSTING]: 'Hosting',
  [PremiumBusinessSectorID.MUNICIPALITY]: 'City / Municipality',
  [PremiumBusinessSectorID.CONSULT]: 'Consulting',
  [PremiumBusinessSectorID.OTHER]: 'Other',
};

export const PremiumPricing = {
  [PremiumBusinessSectorID.CONSTRUCTION]: {
    [PremiumRevenueBracketID.SMALL]: {
      yearlyPlan: {
        targetPrice: 2000,
        showingPrice: 1908,
      },
    },
    [PremiumRevenueBracketID.MEDIUM]: {
      yearlyPlan: {
        targetPrice: 4000,
        showingPrice: 3948,
      },
    },
    [PremiumRevenueBracketID.LARGE]: {
      yearlyPlan: {
        targetPrice: 7000,
        showingPrice: 6948,
      },
    },
    [PremiumRevenueBracketID.GIANT]: {
      yearlyPlan: {
        targetPrice: 12000,
        showingPrice: 12000,
      },
    },
  },
  [PremiumBusinessSectorID.PRIVATE_ENTREPRENEUR]: {
    [PremiumRevenueBracketID.SMALL]: {
      yearlyPlan: {
        targetPrice: 480,
        showingPrice: 468,
      },
    },
    [PremiumRevenueBracketID.MEDIUM]: {
      yearlyPlan: {
        targetPrice: 480,
        showingPrice: 468,
      },
    },
    [PremiumRevenueBracketID.LARGE]: {
      yearlyPlan: {
        targetPrice: 480,
        showingPrice: 468,
      },
    },
    [PremiumRevenueBracketID.GIANT]: {
      yearlyPlan: {
        targetPrice: 480,
        showingPrice: 468,
      },
    },
  },
  [PremiumBusinessSectorID.HOSTING]: {
    [PremiumRevenueBracketID.SMALL]: {
      yearlyPlan: {
        targetPrice: 2000,
        showingPrice: 1908,
      },
    },
    [PremiumRevenueBracketID.MEDIUM]: {
      yearlyPlan: {
        targetPrice: 2000,
        showingPrice: 1908,
      },
    },
    [PremiumRevenueBracketID.LARGE]: {
      yearlyPlan: {
        targetPrice: 2000,
        showingPrice: 1908,
      },
    },
    [PremiumRevenueBracketID.GIANT]: {
      yearlyPlan: {
        targetPrice: 2000,
        showingPrice: 1908,
      },
    },
  },
  [PremiumBusinessSectorID.MUNICIPALITY]: {
    [PremiumRevenueBracketID.SMALL]: {
      yearlyPlan: {
        targetPrice: 8000,
        showingPrice: 7908,
      },
    },
    [PremiumRevenueBracketID.MEDIUM]: {
      yearlyPlan: {
        targetPrice: 8000,
        showingPrice: 7908,
      },
    },
    [PremiumRevenueBracketID.LARGE]: {
      yearlyPlan: {
        targetPrice: 8000,
        showingPrice: 7908,
      },
    },
    [PremiumRevenueBracketID.GIANT]: {
      yearlyPlan: {
        targetPrice: 8000,
        showingPrice: 7908,
      },
    },
  },
  [PremiumBusinessSectorID.CONSULT]: {
    [PremiumRevenueBracketID.SMALL]: {
      yearlyPlan: {
        targetPrice: 2000,
        showingPrice: 1908,
      },
    },
    [PremiumRevenueBracketID.MEDIUM]: {
      yearlyPlan: {
        targetPrice: 7000,
        showingPrice: 6948,
      },
    },
    [PremiumRevenueBracketID.LARGE]: {
      yearlyPlan: {
        targetPrice: 7000,
        showingPrice: 6948,
      },
    },
    [PremiumRevenueBracketID.GIANT]: {
      yearlyPlan: {
        targetPrice: 7000,
        showingPrice: 6948,
      },
    },
  },
  [PremiumBusinessSectorID.OTHER]: {
    [PremiumRevenueBracketID.SMALL]: {
      yearlyPlan: {
        targetPrice: 5500,
        showingPrice: 5388,
      },
    },
    [PremiumRevenueBracketID.MEDIUM]: {
      yearlyPlan: {
        targetPrice: 5500,
        showingPrice: 5388,
      },
    },
    [PremiumRevenueBracketID.LARGE]: {
      yearlyPlan: {
        targetPrice: 5500,
        showingPrice: 5388,
      },
    },
    [PremiumRevenueBracketID.GIANT]: {
      yearlyPlan: {
        targetPrice: 5500,
        showingPrice: 5388,
      },
    },
  },
};

export enum EventLogEntryType {
  USER_REGISTRATION = 1,
  COMPANY_EDIT = 2,
  COMPANY_REGISTRATION = 3,
  RESET_PASSWORD = 5,
  ACTIVATE_ACCOUNT = 6,
  VERIFY_EMAIL = 7,
  WWW_COMPANY_SEARCH = 8,
  WWW_COMPANY_PAGE_VIEW = 9,
  USER_TRIAL = 10,
}

export enum AreaType {
  COUNTRY = 1,
  PROVINCE = 2,
  CITY = 3,
}

export const COUNTRIES = {
  FINLAND: 1,
};

export const StateTexts = {
  [State.OPEN]: 'Open',
  [State.OFFERED]: 'Offered',
  [State.SOLD]: 'Sold',
  [State.PLANNED]: 'Planned',
  [State.DELIVERED]: 'Delivered',
  [State.LOST]: 'Lost',
  [State.DRAFT]: 'Draft',
};

export const UserStateTexts = {
  [UserState.ACTIVE]: 'Active',
  [UserState.INACTIVE]: 'Inactive',
};

export enum ListType {
  LIKED = 1,
  DISLIKED = 2,
  USERLIST = 4,
  // feel free to add more fixed lists - they will appear as personal list
  COMPANYLIST = 100,
}

export const ListTypeTexts = {
  [ListType.LIKED]: 'Tykätyt',
  [ListType.DISLIKED]: 'Vältetyt',
  [ListType.USERLIST]: 'Omat listat',
  [ListType.COMPANYLIST]: 'Jaetut listat',
};

// NOTE2! These are in the database now also, so keep them in sync
export enum BusinessType {
  AOY = 1,
  AY = 2,
  AYH = 3,
  JOY = 4,
  KOY = 5,
  KU = 6,
  KUY = 7,
  KVY = 8,
  KY = 9,
  MUO = 10,
  MUY = 11,
  OK = 12,
  OY = 13,
  OYJ = 14,
  SL = 15,
  SÄÄ = 16,
  VEY = 17,
  VLL = 18,
  VVL = 19,
  XXX = 20,
  YEH = 21,
  SE = 22,
  ELS = 23,
  ETS = 24,
  ETY = 25,
  HY = 26,
  MEY = 27,
  OP = 28,
  SP = 29,
  TYH = 30,
  USK = 31,
  VOY = 32,
  VY = 33,
}

// NOTE1! These are just best guesses...
// NOTE2! These are in the database now also, so keep them in sync (probably remove these eventually)
export const BusinessTypeTexts = {
  [BusinessType.AOY]: 'Asunto-osakeyhtiö',
  [BusinessType.AY]: 'Avoin yhtiö',
  [BusinessType.AYH]: 'AYH',
  [BusinessType.JOY]: 'JOY',
  [BusinessType.KOY]: 'KOY',
  [BusinessType.KU]: 'KU',
  [BusinessType.KUY]: 'KUY',
  [BusinessType.KVY]: 'KVY',
  [BusinessType.KY]: 'Kommandiittiyhtiö',
  [BusinessType.MUO]: 'MUO',
  [BusinessType.MUY]: 'MUY',
  [BusinessType.OK]: 'Osuuskunta',
  [BusinessType.OY]: 'Osakeyhtiö',
  [BusinessType.OYJ]: 'OYJ',
  [BusinessType.SL]: 'SL',
  [BusinessType.SÄÄ]: 'Säätiö',
  [BusinessType.VEY]: 'VEY',
  [BusinessType.VLL]: 'Valtion liikelaitos',
  [BusinessType.VVL]: 'VVL',
  [BusinessType.XXX]: 'XXX',
  [BusinessType.YEH]: 'Yksityinen elinkeinoharkoittaja',
  [BusinessType.SE]: 'Eurooppayhtiö',
  [BusinessType.ELS]: 'Eläkesäätiö',
  [BusinessType.ETS]: 'Eurooppalainen taloudellinen etuyhtymä',
  [BusinessType.ETY]: 'Eurooppalainen taloudellinen etuyhtymä stmp',
  [BusinessType.HY]: 'Hypoteekkiyhdistys',
  [BusinessType.MEY]: 'Metsänhoitoyhdistys',
  [BusinessType.OP]: 'Osuuspankki',
  [BusinessType.SP]: 'Säästöpankki',
  [BusinessType.TYH]: 'Taloudellinen yhdistys',
  [BusinessType.USK]: 'Muu uskonnollinen yhdyskunta',
  [BusinessType.VOY]: 'Vakuutusosakeyhtiö',
  [BusinessType.VY]: 'Vakuutusyhdistys',
};

export const BusinessTypeShortTexts = {
  [BusinessType.AOY]: 'AOY',
  [BusinessType.AY]: 'AY',
  [BusinessType.AYH]: 'AYH',
  [BusinessType.JOY]: 'JOY',
  [BusinessType.KOY]: 'KOY',
  [BusinessType.KU]: 'KU',
  [BusinessType.KUY]: 'KUY',
  [BusinessType.KVY]: 'KVY',
  [BusinessType.KY]: 'KY',
  [BusinessType.MUO]: 'MUO',
  [BusinessType.MUY]: 'MUY',
  [BusinessType.OK]: 'OK',
  [BusinessType.OY]: 'OY',
  [BusinessType.OYJ]: 'OYJ',
  [BusinessType.SL]: 'SL',
  [BusinessType.SÄÄ]: 'SÄÄ',
  [BusinessType.VEY]: 'VEY',
  [BusinessType.VLL]: 'VLL',
  [BusinessType.VVL]: 'VVL',
  [BusinessType.XXX]: 'XXX',
  [BusinessType.YEH]: 'YEH',
  [BusinessType.SE]: 'SE',
  [BusinessType.ELS]: 'ELS',
  [BusinessType.ETS]: 'ETS',
  [BusinessType.ETY]: 'ETY',
  [BusinessType.HY]: 'HY',
  [BusinessType.MEY]: 'MEY',
  [BusinessType.OP]: 'OP',
  [BusinessType.SP]: 'SP',
  [BusinessType.TYH]: 'TYH',
  [BusinessType.USK]: 'USK',
  [BusinessType.VOY]: 'VOY',
  [BusinessType.VY]: 'VY',
};

export enum DataSource {
  UNKNOWN = 1,
  CONSTLE = 2,
  COMPANY = 3,
  PROFINDER = 4,
}

export const DataSourceTexts: { [key: number]: string } = {
  [DataSource.UNKNOWN]: 'Unknown',
  [DataSource.CONSTLE]: 'Constle',
  [DataSource.COMPANY]: 'Yritys',
  [DataSource.PROFINDER]: 'Profinder',
};

export enum DataField {
  NAME = 1,
  MARKETING_NAME = 2,
  BUSINESS_ID = 3,
  DOMICILE = 4,
  BUSINESS_TYPE = 5,
  PHONE = 6,
  EMAIL = 7,
  HOMEPAGE = 8,
  DATE_ESTABLISHED = 9,
  NUMBER_OF_EMPLOYEES = 10,
  TURNOVER = 11,
  DESCRIPTION = 12,
  VISITING_ADDRESS = 13,
  POSTAL_ADDRESS = 14,
  IS_PUBLIC = 15,

  // Classifications
  CONTRACTING_FORMS = 16,
  LANGUAGES = 17,
  MACHINERY_TYPES = 18,
  OPERATIONAL_AREAS = 19,
  PROJECT_TYPES = 20,
  TAITO_COMPETENCES = 21,
  TALO_80 = 22,
  CUSTOMER_TARGET_GROUPS = 23,
  TOL2008 = 24,
  TOL2008_SUBCATEGORIES = 28,
  FONECTA_CATEGORY = 29,
  FONECTA_SUBCATEGORIES = 30,

  CONTACT_PERSONS = 25,
  LOCATIONS = 26,

  // Logo
  LOGO = 27,

  FINANCIALS = 35,
  REFERENCES = 36,
  // NOTE! Current max is FONECTA_SUBCATEGORIES = 30
}

export enum MappingType {
  FONECTA_TO_TALO_80 = 1,
  TOL_TO_TALO_80 = 2,
}

export const DataFieldIdList = Object.keys(DataField)
  .filter((k) => !isNaN(Number(k)))
  .map((v) => parseInt(v));

export const DataFieldName2ID = {
  name: DataField.NAME,
  marketingName: DataField.MARKETING_NAME,
  businessID: DataField.BUSINESS_ID,
  domicile: DataField.DOMICILE,
  businessType: DataField.BUSINESS_TYPE,
  phone: DataField.PHONE,
  email: DataField.EMAIL,
  homepage: DataField.HOMEPAGE,
  dateEstablished: DataField.DATE_ESTABLISHED,
  numberOfEmployees: DataField.NUMBER_OF_EMPLOYEES,
  turnover: DataField.TURNOVER,
  description: DataField.DESCRIPTION,
  visitingAddress: DataField.VISITING_ADDRESS,
  postalAddress: DataField.POSTAL_ADDRESS,
  isPublic: DataField.IS_PUBLIC,

  // Classifications
  contractingForms: DataField.CONTRACTING_FORMS,
  languages: DataField.LANGUAGES,
  machineryTypes: DataField.MACHINERY_TYPES,
  operationalAreas: DataField.OPERATIONAL_AREAS,
  projectTypes: DataField.PROJECT_TYPES,
  taitoCompetences: DataField.TAITO_COMPETENCES,
  talo80: DataField.TALO_80,
  customerTargetGroups: DataField.CUSTOMER_TARGET_GROUPS,
  tol2008: DataField.TOL2008,
  tol2008Subcategories: DataField.TOL2008_SUBCATEGORIES,
  fonectaCategory: DataField.FONECTA_CATEGORY,
  fonectaSubcategories: DataField.FONECTA_SUBCATEGORIES,

  contactPersons: DataField.CONTACT_PERSONS,
  locations: DataField.LOCATIONS,
  references: DataField.REFERENCES,
  financials: DataField.FINANCIALS,

  logo: DataField.LOGO,
};

export const DataFieldID2Name = Object.entries(DataFieldName2ID).reduce((prev, curr) => {
  prev[curr[1]] = curr[0];
  return prev;
}, {} as { [key: number]: string });

export const DataFieldDescriptions: { [key: number]: string } = {
  [DataField.NAME]: 'Name',
  [DataField.MARKETING_NAME]: 'Marketing Name',
  [DataField.BUSINESS_ID]: 'Business ID',
  [DataField.DOMICILE]: 'Domicile',
  [DataField.BUSINESS_TYPE]: 'Business Type',
  [DataField.PHONE]: 'Phone',
  [DataField.EMAIL]: 'Email',
  [DataField.HOMEPAGE]: 'Homepage',
  [DataField.DATE_ESTABLISHED]: 'Established',
  [DataField.NUMBER_OF_EMPLOYEES]: 'Number of employees',
  [DataField.TURNOVER]: 'Turnover',
  [DataField.DESCRIPTION]: 'Description',
  [DataField.VISITING_ADDRESS]: 'Visiting Address',
  [DataField.POSTAL_ADDRESS]: 'Postal Address',
  [DataField.IS_PUBLIC]: 'Public',
  [DataField.CONTRACTING_FORMS]: 'Contracting Forms',
  [DataField.LANGUAGES]: 'Languages',
  [DataField.MACHINERY_TYPES]: 'Machinery Types',
  [DataField.OPERATIONAL_AREAS]: 'Operational Areas',
  [DataField.PROJECT_TYPES]: 'Project Types',
  [DataField.TAITO_COMPETENCES]: 'Taito Competences',
  [DataField.TALO_80]: 'Talo80',
  [DataField.CUSTOMER_TARGET_GROUPS]: 'Customer Target Groups',
  [DataField.TOL2008]: 'Tol2008 (päätoimiala)',
  [DataField.TOL2008_SUBCATEGORIES]: 'Tol2008 (sivutoimiala)',
  [DataField.FONECTA_CATEGORY]: 'Fonecta (päätoimiala)',
  [DataField.FONECTA_SUBCATEGORIES]: 'Fonecta (sivutoimiala)',

  [DataField.CONTACT_PERSONS]: 'Contact Persons',
  [DataField.LOCATIONS]: 'Locations',
  [DataField.FINANCIALS]: 'Financials',
};

export enum ChangeHistoryType {
  COMPANY = 1,
}

export enum CompanyOfficeType {
  HEADQUARTERS = 1,
  BRANCH_OFFICE = 2,
}

export enum EmailTokenType {
  EMAIL_VERIFICATION = 1,
  ACCOUNT_INVITE = 3,
  LOGIN = 4,
}

/** Expiration time per email token type */
export const EmailTokenTypeTTL = {
  [EmailTokenType.EMAIL_VERIFICATION]: 1000 * 60 * 60 * 24 * 7, // Week
  [EmailTokenType.ACCOUNT_INVITE]: 1000 * 60 * 60 * 24 * 7, // Week
  [EmailTokenType.LOGIN]: 1000 * 60 * 5, // 5 minutes
};

export enum InviteTokenType {
  CONTACT_PERSON = 1,
}

export enum ListContactPersonInviteState {
  SENT = 1,
  ACCEPTED = 2,
  CLAIMED = 3,
}

export const DEFAULT_LISTGROUP_NAME = 'Muut';

export const CompanySuperBlockList = [
  '3011298-6', // Controlstep Oy
  '3218998-7', // Investment Ketouman Oy
  '0889595-3', // Golden Dollars Oy
];

/** Get keys from an integer enum */
export function getKeys(constant: any): number[] {
  return Object.entries(constant)
    .filter((entry) => typeof entry[1] === 'number')
    .map((entry) => entry[1]) as number[];
}

export function isValidKey(constant: any, id: number) {
  return getKeys(constant).includes(id);
}
