import { useCallback, useMemo } from 'react';
import create from 'zustand';
import { combine } from 'zustand/middleware';
import {
  fetchContractingForm,
  fetchLanguages,
  fetchMachineryTypes,
  fetchProjectTypes,
  fetchTaitoCompetences,
  fetchTargetGroup,
  fetchTol2008,
  WWWClassificationState,
} from '../lib/classifications';
import { useScopedState } from '../lib/state';
import { convertItemsToState, getOptionSelectedStates } from '../common-react/selectOptionLogic';
import { staticState } from './staticStore';

export interface SelectedCountPair {
  selected: string[];
  count: number;
}

// Automatically enforce SearchState to contain all keys in ClassificationState
type SearchOptionsState = Record<keyof WWWClassificationState, SelectedCountPair>;

export type SearchSliderOptionsState = {
  numberOfEmployees: [number, number];
  turnover: [number, number];
  dateEstablished: [number, number];
};

export type AdvancedSearchState = SearchOptionsState & SearchSliderOptionsState;

/** List of classifications shown in the "advanced options" section */
export const advancedSearchOptions = [
  'contractingForms',
  'languages',
  'machineryTypes',
  'projectTypes',
  'taitoCompetences',
  'targetGroups',
  'tol2008',
] as const;

/** Slider marks */
export const numberOfEmployeeMarks = [0, 10, 40, 100, 200, 400, 800];
export const turnoverMarks = [0, 500, 2000, 5000, 10000, 20000, 50000, 100000, 500000];

const getRange = (min: number, max: number, step?: number) => {
  step = step || 1;
  let o = [];
  for (let i = 0; i <= max - min; i += step) {
    o.push(min + i);
  }
  return o;
};

export const dateEstablishedMarks = [...getRange(1800, 1960, 5), ...getRange(1960, 2000, 2), ...getRange(2001, new Date().getFullYear())];

export const advancedSearchStateDefaults: AdvancedSearchState = {
  numberOfEmployees: [0, numberOfEmployeeMarks.length - 1],
  dateEstablished: [0, dateEstablishedMarks.length - 1],
  turnover: [0, turnoverMarks.length - 1],

  contractingForms: { selected: [], count: 0 },
  languages: { selected: [], count: 0 },
  machineryTypes: { selected: [], count: 0 },
  operationalAreas: { selected: [], count: 0 },
  projectTypes: { selected: [], count: 0 },
  taitoCompetences: { selected: [], count: 0 },
  talo80: { selected: [], count: 0 },
  targetGroups: { selected: [], count: 0 },
  tol2008: { selected: [], count: 0 },
};

const advancedSearchStateDefaultsString = JSON.stringify(advancedSearchStateDefaults);

/** Store */
export const advancedSearchState = create(combine(advancedSearchStateDefaults, (set) => ({ set })));

/** Helper to map selected ID string to numeric values */
const stateStringIDtoNumeric = (id: string) => parseInt(id);

const fromSliderToMinMax = (value: [number, number], marks: number[], multiplier: number = 1) => {
  const [left, right] = [...value].sort((a, b) => a - b);

  let minMax: { min?: number; max?: number } = {};
  if (left > 0) minMax.min = marks[left] * multiplier;
  if (right < marks.length - 1) minMax.max = marks[right] * multiplier;

  return minMax;
};

/**
 * Convert a advanced search data into search API request parameters
 */
export const getAdvancedSearchQueryParameters = () => {
  const {
    projectTypes,
    contractingForms,
    targetGroups,
    machineryTypes,
    languages,
    talo80,
    taitoCompetences,
    operationalAreas,
    tol2008,
    numberOfEmployees,
    dateEstablished,
    turnover,
  } = advancedSearchState.getState();

  return {
    projectTypeIDs: projectTypes.selected.map(stateStringIDtoNumeric),
    contractingFormIDs: contractingForms.selected.map(stateStringIDtoNumeric),
    customerTargetGroupIDs: targetGroups.selected.map(stateStringIDtoNumeric),
    machineryTypeIDs: machineryTypes.selected.map(stateStringIDtoNumeric),
    // competenceIDs?: number[];
    languageIDs: languages.selected.map(stateStringIDtoNumeric),
    talo80IDs: talo80.selected.map(stateStringIDtoNumeric),
    taitoCompetenceIDs: taitoCompetences.selected.map(stateStringIDtoNumeric),
    operationalAreaIDs: operationalAreas.selected.map(stateStringIDtoNumeric),
    tol2008IDs: tol2008.selected.map(stateStringIDtoNumeric),

    numberOfEmployees: fromSliderToMinMax(numberOfEmployees, numberOfEmployeeMarks),
    dateEstablished: fromSliderToMinMax(dateEstablished, dateEstablishedMarks),
    turnover: fromSliderToMinMax(turnover, turnoverMarks, 1000),
  };
};

/**
 * Fetch all "advanced" search options
 * Used to bake the "advanced" search options in to the front page so they can be visible instantly
 */
export const fetchAdvancedSearchOptions = async (): Promise<Pick<WWWClassificationState, typeof advancedSearchOptions[number]>> => {

  try {
    const { contractingForms } = await fetchContractingForm();
    const { languages } = await fetchLanguages();
    const { machineryTypes } = await fetchMachineryTypes();
    const { projectTypes } = await fetchProjectTypes();
    const { taitoCompetences } = await fetchTaitoCompetences();
    const { targetGroups } = await fetchTargetGroup();
    const { tol2008 } = await fetchTol2008();
  
    return { contractingForms, languages, machineryTypes, projectTypes, taitoCompetences, targetGroups, tol2008 };
  } catch(e) {
    console.log("CATCHED", e);
    
  }
  return { contractingForms: [], languages: [], machineryTypes: [], projectTypes: [], taitoCompetences: [], targetGroups: [], tol2008: [] };
};

/** Get and set search filters easily */
export const useSearchFilter = (path: keyof SearchOptionsState) => {
  const { set, ...state } = useScopedState(advancedSearchState, [path]);

  const filterState = state[path];

  const setFilterState = (value: SelectedCountPair) => {
    set({ [path]: value } as any);
  };

  return {
    state: filterState,
    set: setFilterState,
  };
};

/** Get search dirty state easily */
export const useSearchDirty = () => {
  return advancedSearchState((state) => JSON.stringify(state) !== advancedSearchStateDefaultsString);
};

/** Returns a function that can be used to check if an ID is selected in a certain classification */
export const useCheckSelectedID = (path: keyof SearchOptionsState) => {
  const { set, ...state } = useScopedState(advancedSearchState, [path]);
  const items = useScopedState(staticState, [path])[path];

  const filterState = state[path];

  // Convert classifications items and selected IDs into the actual selected IDs list, not just a selected parents list
  const itemDictionary = useMemo(() => convertItemsToState(items, 'fi'), [items]);
  const selectedFilterState = useMemo(() => getOptionSelectedStates(filterState.selected, itemDictionary), [itemDictionary, filterState]);
  const selectedIDs = useMemo(
    () =>
      Object.keys(selectedFilterState)
        .filter((id) => selectedFilterState[id].isSelected)
        .map((id) => id),
    [selectedFilterState]
  );

  const isIDSelected = useCallback((id: string | number) => selectedIDs.includes(`${id}`), [selectedIDs]);

  return isIDSelected;
};
