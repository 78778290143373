import { useState, useEffect } from 'react';
import { LoginContext, loginContext, loginContextDefaults } from './loginContext';
import { useState as useHookstate } from '@hookstate/core';
import { cloneState } from '../lib/utils';
import { AccountPremiumLevel } from '../common/Constants';

export const useUser = () => {
  const [user, setUser] = useState<LoginContext>({ ...loginContextDefaults });
  const [isSSRRender, setIsSSRRender] = useState(true);
  const loginState = useHookstate(loginContext);
  const isLoggedIn = user.account?.accountCode !== '' ? true : false;
  const hasPremium = isLoggedIn && user.account?.premiumLevelID > AccountPremiumLevel.FREE;

  // Set user state after first render, this prevents SSR conflicts
  useEffect(() => {
    setUser(cloneState(loginState.get()) as LoginContext);
    if (isSSRRender) setIsSSRRender(false);
  }, [JSON.stringify(loginState.get())]);

  // Debugging (TODO: turn of in prod?)
  if (typeof window !== 'undefined') {
    const w = window as any;
    w._dbg = { ...w._dbg, user: JSON.parse(JSON.stringify(loginState.get())) };
  }

  return {
    isLoggedIn,
    hasPremium,
    accessRoleID: user.accessRoleID,
    isContactPersonInvitePending: user.isContactPersonInvitePending,
    isPremiumTrialUsed: user.isPremiumTrialUsed,
    get user() { return user }, // prettier-ignore
    get companies() { return user.companies || [] }, // prettier-ignore
    get account() { return user.account }, // prettier-ignore

    /**
     * Is true on the first render pass.
     * If you need to write logic that has to know user state right away,
     * like a redirect to /login, use this to skip the first render pass
     */
    isSSRRender,
  };
};
