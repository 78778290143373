import { CSSProperties, FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { transparentize, darken, lighten } from 'polished';
import InfoCircleIcon from 'mdi-react/InfoCircleIcon';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import CheckDecagramIcon from 'mdi-react/CheckDecagramIcon';
import Typography from '@material-ui/core/Typography';
import { Color, borderRadius, mediaQuery } from './Theme';

const errorPulseAnimation = (color: string) => keyframes`
  0% { background-color: ${transparentize(0.2, color)} }
  50% { background-color: ${transparentize(0.6, color)} }
  100% { background-color: ${transparentize(0.2, color)} }
`;

export const BannerBox = styled.div<{ textColor: string; backgroundColor: string; flash: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${borderRadius}px;
  color: ${({ textColor }) => textColor};

  ${({ backgroundColor, flash }) =>
    flash &&
    css`
      animation-name: ${errorPulseAnimation(backgroundColor)};
      animation-duration: 500ms;
      animation-iteration-count: 2;
    `};

  ${mediaQuery.small(css`
    flex-direction: column;
    align-items: flex-end;
  `)}

  svg {
    flex-shrink: 0;
    color: inherit;
    opacity: 0.7;
  }

  .MuiButton-label {
    color: ${({ textColor }) => transparentize(0.1, textColor)};
  }

  .MuiButton-contained {
    background-color: ${({ textColor }) => transparentize(0.1, textColor)};

    .MuiButton-label {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      color: ${({ backgroundColor }) => backgroundColor};
    }

    &:hover {
      background-color: ${({ textColor }) => transparentize(0.3, textColor)};
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  > .MuiTypography-root {
    flex: 1;
    margin-left: 0.9rem;
    color: inherit;
    opacity: 1;
  }

  > .MuiButtonBase-root {
    flex-shrink: 0;
    margin-bottom: -3px;

    ${mediaQuery.small(css`
      margin-top: 1rem;
    `)}
  }
`;

export interface BannerProps {
  type?: 'info' | 'warning' | 'error' | 'success';
  icon?: JSX.Element | null;
  button?: JSX.Element;
  style?: CSSProperties;
}

export const Banner: FC<BannerProps> = ({ type = 'info', icon, button = null, style = {}, children }) => {
  let visibleIcon = null;
  if (type === 'info') visibleIcon = <InfoCircleIcon />;
  if (type === 'warning') visibleIcon = <AlertCircleIcon />;
  if (type === 'error') visibleIcon = <AlertCircleIcon />;
  if (type === 'success') visibleIcon = <CheckDecagramIcon />;
  if (icon || icon === null) visibleIcon = icon;

  let color = Color.background;
  if (type === 'warning') color = Color.primary;
  if (type === 'error') color = Color.red;
  if (type === 'success') color = Color.green;

  let textColor = darken(0.2, color);
  if (type === 'info') textColor = Color.black;
  if (type === 'error') textColor = Color.white;

  let backgroundColor = transparentize(0.8, color);
  if (type === 'info') backgroundColor = Color.background;
  if (type === 'error') backgroundColor = transparentize(0.2, Color.red);

  return (
    <BannerBox className={type} textColor={textColor} backgroundColor={backgroundColor} flash={type === 'error'} style={style}>
      <Content>
        {visibleIcon}
        <Typography variant="body2">{children}</Typography>
      </Content>
      {button}
    </BannerBox>
  );
};
