export const gaConsent = (statistics: boolean, marketing: boolean) => {
  (window as any).gtag('consent', 'update', {
    analytics_storage: statistics ? 'granted' : 'denied',
    ad_storage: marketing ? 'granted' : 'denied',
  });
};

// Log specific event
export const gaEvent = (action: string, params?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams) => {
  (window as any).gtag('event', action, params);
};
