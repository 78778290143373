import { FC } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import CheckBoldIcon from 'mdi-react/CheckBoldIcon';
import { Color } from '../components/Theme';

const Styled = {
  Badge: styled.div<{ plain?: boolean }>`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-right: 0.3rem;
    padding: 0.1rem 0.5rem 0.1rem ${({ plain }) => (plain ? 0.5 : 0.3)}rem;
    min-width: 22px;
    min-height: 22px;
    font-size: 0.9rem;
    background-color: ${Color.primary};
    border-radius: 2rem;
    color: ${Color.white};
    user-select: none;

    svg {
      margin-left: -0.15rem;
      margin-right: 0.25rem;
    }
  `,
};

export const Badge: FC<{ icon?: JSX.Element; plain?: boolean; className?: string }> = ({
  icon = null,
  plain,
  className = '',
  children,
}) => (
  <Styled.Badge plain className={classnames('badge', className)}>
    {!plain && (icon || <CheckBoldIcon size={14} />)} {children}
  </Styled.Badge>
);
