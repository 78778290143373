import { createState, Plugin, State, StateValueAtRoot, PluginCallbacks } from '@hookstate/core';
import { FormattedMessage } from 'react-intl';
import { nextApiRequest } from '../lib/utils';
import { ListType } from '../../common/Constants';
import { gaEvent } from '../lib/ga';

/**
 * Hookstate plugin to keep state in local storage
 * Modified to work with Next.js
 * Original: https://github.com/avkonst/hookstate/blob/master/plugins/Persistence/src/Persistence.ts
 */
export const persistState =
  (localStorageKey: string): (() => Plugin) =>
  () => ({
    id: Symbol('LocalPersistence'),
    init: (state: State<StateValueAtRoot>) => {
      const isInBrowser = typeof window !== 'undefined';
      let hasPreviousValue = false;

      if (isInBrowser) {
        const persisted = window.localStorage.getItem(localStorageKey);
        if (persisted !== null) {
          const result = JSON.parse(persisted);
          state.set(result);
          hasPreviousValue = true;
        }
      }

      if (isInBrowser && !hasPreviousValue && !state.promised && !!!state.error) {
        localStorage.setItem(localStorageKey, JSON.stringify(state.value));
      }

      return {
        onSet: (p) => {
          if ('state' in p) {
            localStorage.setItem(localStorageKey, JSON.stringify(p.state));
          } else {
            localStorage.removeItem(localStorageKey);
          }
        },
      } as PluginCallbacks;
    },
  });

/* NOTE! This should be synced with me.ts TODO: refactor */
export interface LoginContext {
  userID: number;
  accessRoleID: number;
  email: string;
  firstname: string;
  lastname: string;
  account: {
    accountCode: string;
    premiumLevelID: number;
  };
  companies: {
    companyID: number;
    name: string;
    businessID: string;
    url: string;
    logo: { path: string } | null;
    isPublic: boolean;
    isPending: boolean;
    isCompanyManualFormPending: boolean;
    isUserManualFormPending: boolean;
  }[];
  lists: {
    listID: number;
    listTypeID: ListType;
    label: string;
  }[];
  isContactPersonInvitePending: boolean;
  isPremiumTrialUsed: boolean;
}

export const loginContextDefaults: LoginContext = {
  userID: 0,
  firstname: '',
  lastname: '',
  email: '',
  accessRoleID: 0,
  account: { accountCode: '', premiumLevelID: 0 },
  companies: [],
  lists: [],
  isContactPersonInvitePending: false,
  isPremiumTrialUsed: false,
};

export const loginContext = createState<LoginContext>({
  ...loginContextDefaults,
});

/** Keep this state in local storage */
loginContext.attach(persistState('constle.login'));

export type RegisterAction<T, D = {}> = (data: T) => { status: string; error?: string | JSX.Element } & D;

export type LoginActionAsync<T = {}, D = {}> = (data?: T) => Promise<{ status: string; error?: string | JSX.Element } & D>;

/**
 * Clear saved state
 */
export const clearLoginState = () => {
  loginContext.set(loginContextDefaults);
};

/**
 * Validate user session
 */
export const validateSession: LoginActionAsync<{}, { userID?: number }> = async () => {
  /** Attempt to validate session */
  const { status, ...rest } = await nextApiRequest(`/api/v1/www/auth/me`, {});

  /** Session has expired */
  if (status !== 'ok') {
    clearLoginState();
    return { status: 'invalid session' };
  }

  /** Update cached information */
  loginContext.set(rest as any);

  return { status: 'ok', userID: rest.userID };
};

/**
 * Add list to user session
 */
export const addList = (list: LoginContext['lists'][number]) => {
  const lists = JSON.parse(JSON.stringify(loginContext.lists.get()));
  loginContext.lists.set([...lists, list]);
};

/**
 * Remove list from user session
 */
export const removeList = (listID: number) => {
  const lists = JSON.parse(JSON.stringify(loginContext.lists.get()));
  loginContext.lists.set(lists.filter((list) => list.listID !== listID));
};

/**
 * Log the user out
 */
export const logoutUser = async () => {
  await nextApiRequest(`/api/v1/www/auth/logout`, {});
  clearLoginState();
  localStorage.removeItem('constle.login');
};

/**
 * Login the user
 */
export const loginUser: LoginActionAsync<{ email: string }, { token?: string }> = async ({ email }) => {
  /** Validate email */
  if (!email || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    return {
      status: 'invalid email',
      error: (
        <FormattedMessage
          id="login.invalidEmail"
          defaultMessage="Virheellinen sähköpostiosoite. Tarkista että sähköpostiosoite on syötetty oikein."
        />
      ),
    };
  }

  /** Attempt to login the user */
  const { status, token } = await nextApiRequest(`/api/v1/www/auth/login`, {
    email,
  });

  if (status !== 'ok') {
    return {
      status,
      error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    };
  }

  // /** Save session token */
  // //loginContext.sessionToken.set(token);

  // /** Validate session and get user information */
  // const { status: sessionStatus } = await validateSession({});

  // if (sessionStatus !== 'ok') {
  //   return {
  //     status,
  //     error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
  //   };
  // }

  // gaEvent('login');

  return { status: 'ok', token };
};

/**
 * Login the user
 */
export const pollLogin: LoginActionAsync<{ token: string }> = async ({ token: pollingToken }) => {
  /** Check login status */
  const { status, token } = await nextApiRequest(`/api/v1/www/auth/login-poll`, {
    token: pollingToken,
  });

  if (status !== 'ok') return { status };

  /** Save session token */
  //loginContext.sessionToken.set(token);

  // Check if the session has updated
  const { status: authStatus } = await nextApiRequest(`/api/v1/www/auth/me`, {});
  if (authStatus !== 'ok') return { status: authStatus };

  /** Validate session and get user information */
  const { status: sessionStatus } = await validateSession({});

  if (sessionStatus !== 'ok') {
    return {
      status,
      error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    };
  }

  gaEvent('login');

  return { status: 'ok' };
};

/**
 * Start the premium trial & register
 */
export const startTrial: LoginActionAsync<{ email: string; url?: string }, { token?: string }> = async ({ email, url }) => {
  /** Validate email */
  if (!email || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    return {
      status: 'invalid email',
      error: (
        <FormattedMessage
          id="login.invalidEmail"
          defaultMessage="Virheellinen sähköpostiosoite. Tarkista että sähköpostiosoite on syötetty oikein."
        />
      ),
    };
  }

  /** Attempt to start the trial */
  const { status, token } = await nextApiRequest(`/api/v1/www/auth/trial`, {
    email,
    url,
  });

  if (status === 'email in use') {
    return {
      status,
      error: <FormattedMessage id="register.emailExists" defaultMessage="Sähköpostiosoite on käytössä. Ole hyvä ja kirjaudu sisään." />,
    };
  }

  if (status !== 'ok' || !token) {
    return {
      status,
      error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
    };
  }

  /** Validate session and get user information */
  // const { status: sessionStatus } = await validateSession({});

  // if (sessionStatus !== 'ok') {
  //   return {
  //     status,
  //     error: <FormattedMessage id="unknownError" defaultMessage="Jokin meni vikaan, yritä myöhemmin uudelleen…" />,
  //   };
  // }

  gaEvent('register');
  gaEvent('trial');

  return { status: 'ok', token };
};
