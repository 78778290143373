import { FC, CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Header } from './Header';
import { BannerBox } from './Banner';
import { Color, cardStyles, mediaQuery } from './Theme';

const Content = styled.div<{ fullPage?: boolean }>`
  ${cardStyles}
  margin-bottom: 1.5rem;
  padding: 2.5rem;
  width: 100%;
  max-width: 600px;

  ${BannerBox} {
    &:not(.error) {
      margin-bottom: 2rem;
    }

    &.error {
      margin-top: 1rem;
    }
  }

  .MuiStepper-root {
    padding: 2.5rem 0 0.8rem 0;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 1rem;
  }

  .MuiStepLabel-label {
    font-weight: 500;
    color: ${Color.black};
  }

  .MuiStepContent-root {
    padding-left: 1.75rem;
    .MuiTypography-body2 {
      opacity: 0.8;
    }
  }

  .MuiSvgIcon-root text {
    font-weight: bold;
    fill: ${Color.white};
  }

  > .MuiTypography-body2 {
    margin-bottom: 2rem;
    opacity: 0.8;
  }

  hr {
    margin-top: 2rem;
    margin-left: -2.5rem;
    width: calc(100% + 5rem);
    height: 2px;
    background-color: ${Color.background};
    border: none;
  }

  ${({ fullPage }) =>
    mediaQuery.small(css`
      margin: 0;
      padding: 2rem;
      max-width: 100%;
      width: 100%;

      ${fullPage &&
      css`
        border-bottom: 2px solid ${Color.background};
        border-radius: 0;
      `}

      hr {
        margin-left: -2rem;
        width: calc(100% + 4rem);
      }
    `)}
`;

const ContentMain = styled.div`
  > .MuiFormControl-root:not(:first-of-type),
  > form .MuiFormControl-root:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

const Control = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 0 0;
`;

export interface ModalProps {
  id?: string;
  title: string | JSX.Element;
  icon?: JSX.Element;
  description: string | JSX.Element;
  fullPage?: boolean;
  style?: CSSProperties;
}

/** Modal component + special subcomponents */
type ModalType = FC<ModalProps> & { Control: typeof Control; Content: typeof Content };

const Modal: ModalType = ({ id, title, icon, description, style = {}, fullPage = true, children }) => {
  return (
    <Content id={`${id}-modal`} fullPage={fullPage} style={style}>
      <Header id={`${id}-modal-title`} icon={icon}>
        {title}
      </Header>
      <Typography id={`${id}-modal-description`} variant="body2">
        {description}
      </Typography>

      {children}
    </Content>
  );
};

Modal.Content = ContentMain;

Modal.Control = Control;

export default Modal;
