import { css, FlattenSimpleInterpolation } from 'styled-components';
import { createTheme } from '@material-ui/core/styles';
import { lighten } from 'polished';

export const Color = {
  primary: '#EF762F',
  secondary: '#000B6E',
  disabled: '#A2A3A6',
  background: '#F0F0F0',
  select: lighten(0.4, '#EF762F'),
  hover: lighten(0.775, '#000B6E'),
  white: '#FFFFFF',
  black: '#222222',

  red: '#d32f2f',
  green: '#2e7d32',
};

export const MuiTheme = createTheme({
  palette: {
    primary: {
      main: Color.primary,
    },
    secondary: {
      main: Color.secondary,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: Color.secondary,
        },
      },
    },
  },
});

export const borderRadius = 4;
export const navbarHeight = 66;
export const companyRowHeight = 92;
export const compactCompanyRowHeight = 62;

export const breakPoint = {
  large: 1920,
  medium: 1300,
  small: 800,
};

/** Shorthand to use media queries in styled-components */
export const mediaQuery = {
  large: (style: FlattenSimpleInterpolation) => css`
    @media (min-width: ${breakPoint.medium + 1}px) {
      ${style}
    }
  `,
  medium: (style: FlattenSimpleInterpolation) => css`
    @media (max-width: ${breakPoint.medium}px) {
      ${style}
    }
  `,
  small: (style: FlattenSimpleInterpolation) => css`
    @media (max-width: ${breakPoint.small}px) {
      ${style}
    }
  `,
  mobileFirst: {
    large: (style: FlattenSimpleInterpolation) => css`
      @media (min-width: ${breakPoint.medium + 1}px) {
        ${style}
      }
    `,
    medium: (style: FlattenSimpleInterpolation) => css`
      @media (min-width: ${breakPoint.small + 1}px) {
        ${style}
      }
    `,
  },
};

export const layoutStyles = css`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;

  @media (max-width: ${breakPoint.medium}px) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    box-sizing: border-box;
  }
`;

export const cardStyles = css`
  margin: 0 auto 4rem auto;
  width: 100%;
  max-width: 1200px;
  background-color: ${Color.white};
  border-radius: ${borderRadius}px;

  box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
  box-sizing: border-box;
`;
