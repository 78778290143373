import { CSSProperties, FC } from 'react';
import styled, { css } from 'styled-components';
import { Color, cardStyles } from '../components/Theme';

const Styled = {
  Header: styled.h2<{ small?: boolean; tiny?: boolean; icon: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    color: ${Color.secondary};
    ${cardStyles}
    margin: 0 auto 1rem auto;
    min-height: 22px;
    padding-left: ${({ icon }) => (icon ? 2.2 : 0)}rem;
    font-size: ${(props) => (props.small ? 1.1 : 1.5)}rem;
    background-color: transparent;
    box-shadow: none;

    small {
      display: block;
      margin-top: 0.2rem;
      font-size: 0.8rem;
      font-weight: 500;
      color: ${Color.disabled};
    }

    > svg,
    > .icon {
      position: absolute;
      left: 0;
      margin-top: 1px;
      opacity: 0.8;
    }

    .badge {
      margin-left: 0.5rem;
    }

    ${({ tiny }) =>
      tiny &&
      css`
        padding-left: 1.5rem;
        font-size: 1rem;

        > svg {
          width: 1rem;
          height: 1rem;
          opacity: 0.6;
        }
      `}
  `,
};

export const Header: FC<{ id?: string; small?: boolean; tiny?: boolean; icon?: JSX.Element; style?: CSSProperties }> = ({
  id,
  small,
  tiny,
  icon,
  children,
  ...other
}) => (
  <Styled.Header id={id} small={small} tiny={tiny} icon={!!icon} {...other}>
    {icon}
    {children}
  </Styled.Header>
);
