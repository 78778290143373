import { forwardRef } from 'react';
import styled from 'styled-components';
import { Color } from './Theme';

const LogoContainer = styled.div`
  > span {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: ${Color.white};
    user-select: none;
  }
`;

export const Logo = forwardRef<SVGSVGElement, { height?: number; color?: string }>(({ height = 24, color = Color.primary }, ref) => (
  <LogoContainer>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 523 105" style={{ height: height - 2 }} ref={ref}>
      <g fill={color} clipPath="url(#clip0)">
        <path d="M411.3 0h26.2v102.3h-26.2V0zM220.1 68V28.2h-26v74h26V68zM240.2 26.5A31 31 0 00228 29v23.3c1-.3 2.1-.4 3.2-.4 6.2 0 10.1 4.2 10.1 10.7v39.8h26.2V53.7c0-16.4-11-27.2-27.3-27.2zM385.7 71.4V59.9h-26v15.8c0 16.2 8.3 28.4 28.4 28.4 4.1 0 8.3-.7 12.1-2.2V77.5c-2 .8-4.2 1.3-6.5 1.3-5.5 0-8-3-8-7.4zM403 52V28.3h-17.3V8.7h-26v19.6h-10V52H403zM288.3 77.2l-11.1 16c9.4 7.1 21 11 32.8 10.9V84.4h-1c-8.2 0-14.7-3-20.7-7.2zM522.8 65.9a38.2 38.2 0 00-39.2-39.4A38.3 38.3 0 00443.8 65c0 22.3 17.9 39.2 41.7 39.2 13.2 0 24.8-5.1 32.8-13.4l-17.1-15.1a21.2 21.2 0 01-15 6.4c-6.8 0-11.9-3.5-14.2-9.3v-15c2-5.6 6-9.1 11.3-9.1 5.2 0 9.7 3.3 11.6 9.1h-15.1v15h42.4c.4-2.3.6-4.5.6-6.8zM130.8 65.3V28.6a37.9 37.9 0 00-26.9 36.7c0 22 17.3 38.8 40.2 38.8 1.9 0 3.7-.2 5.5-.4v-26a13.9 13.9 0 01-15-2.8 13 13 0 01-3.8-9.6z" />
        <path d="M144.1 26.5c-1.8 0-3.7.2-5.5.4v26a13.8 13.8 0 0115.1 2.8 13 13 0 013.8 9.6V102a37.9 37.9 0 0026.8-36.7c0-22-17.2-38.8-40.2-38.8zM342.4 36c-8.7-6-18.9-9.2-29.4-9.4V46a37 37 0 0118.2 5.8l11.2-16z" />
        <path d="M315.6 54.1c-6-1.1-10.4-2.2-10.4-4.8V26.9c-14.7 1.8-25.7 9.9-25.7 24.6 0 18.3 16.3 21.8 27.6 24.1 6.4 1.3 10.7 2.3 10.7 5V103.6c15-2.2 25.7-10.7 25.7-25.3 0-18.3-16.5-22-27.9-24zM75.6 63.7a24.2 24.2 0 01-22.3 13.6c-14.1 0-24.8-10.7-24.8-25V6A50.6 50.6 0 000 52.2c0 29.5 23 51.9 53.3 51.9 21.3 0 38.8-11 47.5-27.7L75.6 63.7z" />
        <path d="M53.3 27.1A24.3 24.3 0 0175 39.4l25.6-12A52.4 52.4 0 0053.3.4c-5.8 0-11.5.8-17 2.5v30.7a24.7 24.7 0 0117-6.5z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h522.8v104.1H0z" />
        </clipPath>
      </defs>
    </svg>
    <span>Älykäs urakoitsijakanta</span>
  </LogoContainer>
));
