import { ClassificationItem, ClassificationStateItem, ClassificationState } from './documents/ClassificationDocument';

/** Helper to recursively find all parents of an item */
const getItemParentIDs = (id: number | string, items: ClassificationItem[]): (number | string)[] => {
  const item = items.find((i) => i.id === id);
  if (!item || item.parentID === null || item.parentID === undefined) return [];
  return [item.parentID, ...getItemParentIDs(item.parentID!, items)];
};

/** Helper to recursively find all children of an item */
const getItemChildIDs = (id: number | string, items: ClassificationItem[]): (number | string)[] => {
  const children = items.filter((item) => item.parentID === id);
  return [
    ...children.map((item) => item.id),
    ...children.reduce<(number | string)[]>((ids, item) => [...ids, ...getItemChildIDs(item.id, items)], []),
  ];
};

/** Convert "options" style data strcuture to a selection friendly linked list */
export const convertClassificationItemsToState = (items: ClassificationItem[], locale: string | null = null) => {
  const state: ClassificationState = {};

  for (const item of items) {
    state[item.id] = {
      id: item.id,
      name: locale ? (item.nameTranslations ? item.nameTranslations[locale] : null) ?? item.name : item.name,
      parents: getItemParentIDs(item.id, items),
      children: getItemChildIDs(item.id, items),
    };
  }

  return state;
};

/**
 * Validate & repair a list of selected classification IDs
 * @param selectedIDs List of selected IDs
 * @param state The state item list to get relationships from
 * @returns Validated list of selected IDs
 */
export const validateSelectedClassificationIDs = (selectedIDs: number[], state: ClassificationState) => {
  const validatedSelectedIDs: number[] = [];
  let calculatedSelectedIDs: number[] = [];

  // Make sure all selected IDs exist in state
  for (const id of selectedIDs) {
    if (state[id]) {
      validatedSelectedIDs.push(id);
    } else {
      console.warn(`⚠️ Removed ID (${id}) because it does not exist in the given classification state`);
    }
  }

  // Make sure all children are selected if the parent is selected
  for (const id of selectedIDs) {
    if (state[id].children.length) {
      calculatedSelectedIDs.push(...state[id].children);
    } else {
      calculatedSelectedIDs.push(id);
    }
  }

  // Make sure only a parent is selected if all its children are selected
  for (const item of (Object.values(state) as ClassificationStateItem[])
    .filter((item) => !!item.children.length)
    .sort((a, b) => a.parents.length - b.parents.length)) {
    const nonParentChildren = item.children.filter((id) => !state[id].children.length);
    const selectedNonParentChildren = nonParentChildren.filter((item) => calculatedSelectedIDs.includes(item));

    if (nonParentChildren.length === selectedNonParentChildren.length) {
      calculatedSelectedIDs = calculatedSelectedIDs.filter((id) => !item.children.includes(id));
      calculatedSelectedIDs.push(item.id);
    }
  }

  return calculatedSelectedIDs;
};
