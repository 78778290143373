import Hashids from 'hashids';
import { captureException } from '@sentry/browser';

export const formatNumber = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const delay = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

interface ApiRequestResponse {
  status: string;
  [key: string]: any;
}

/** Generic api cal
 *  1. Always post request with json as payload
 *  2. Successful response assumed to be valid json returning 200
 *  3. Always returns object with a status member (regardless of error)
 *  4. Success always returns { status: "ok", ... }
 *  5. When doing SSR will call the API using localhost
 *  NOTE! Timeout doesn't work for SSR AbortController is added to node in v15, so just commented out here, maybe we shouldn't use fetch here?
 */
export const nextApiRequest = async (
  url: string,
  payload: object = {},
  options: { timeout?: number; delay?: number } = { timeout: 10000, delay: 0 }
): Promise<ApiRequestResponse> => {
  options.timeout = options.timeout ?? 10000;
  options.delay = options.delay ?? 0;

  const controller = process.browser ? new AbortController() : null;
  const timerID = process.browser ? setTimeout(() => controller.abort(), options.timeout) : 0;
  const startTime = Date.now();

  const catchSentryError = (error: Error, result: any = null) => {
    try {
      const sentryPayload = { ...(payload || {}) };
      delete sentryPayload['password'];
      captureException(error, {
        url: process.browser ? url : 'http://127.0.0.1:3001' + url,
        payload: sentryPayload,
        response: result,
      } as any);
    } catch (error) {
      captureException(error);
    }
  };

  let res;
  try {
    console.log(url, process.browser ? url : 'http://127.0.0.1:3001' + url);
    res = await fetch(process.browser ? url : 'http://127.0.0.1:3001' + url, {
      ...(process.browser ? { signal: controller.signal } : {}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (res.status != 200) return { status: 'statuscode ' + res.status };
  } catch (err) {
    console.log(res);
    console.log('nextApiRequest failed:', err.message);
    catchSentryError(err);
    if (err == 'DOMException: The user aborted a request.') return { status: 'network error' }; // Timeout
    if (err == 'TypeError: Failed to fetch') return { status: 'network error' };
    return { status: 'unexpected network error' };
  }
  if (process.browser) clearTimeout(timerID as number);

  let result;
  try {
    result = await res.json();
  } catch (e) {
    return { status: 'json expected' };
  }

  if (typeof result != 'object' && typeof result.status != 'string') {
    console.log('nextApiRequest illegal response(2)', result);
    return { status: 'illegal response' };
  }

  // Simulate longer request delay if requested
  const timeElapsed = Date.now() - startTime;
  if (options.delay && result.status !== 'ok' && timeElapsed < options.delay) {
    await delay(options.delay - timeElapsed);
  }

  // Inform sentry about unknown errors
  if (result.status === 'unexpected error') {
    catchSentryError(new Error(`"unexpected error" when requesting "${process.browser ? url : 'http://127.0.0.1:3001' + url}"`), result);
  }

  return result;
};

// TODO: THIS IS DUPLICATED IN API SERVER = BAAD!
// TODO: salt here is not a big secret (yet at least) but should probably not be in the code anyway
const companyIDHashID = new Hashids('mysecretsaltXX--', 4, 'abcdefghijklmnopqrstuvwxyz0123456789');
export const encodeCompanyIDHash = (companyID: number) => companyIDHashID.encode(companyID);
export const decodeCompanyIDHash = (hash: string): number => companyIDHashID.decode(hash)[0] as number;

// TODO: THIS IS DUPLICATED IN API SERVER = BAAD!
// NOTE! Code from gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
export const slugify = (string: string) => {
  if (!string) return '';
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

// TODO: THIS IS DUPLICATED IN API SERVER = BAAD!
export const companyUrl = ({
  lang = 'fi',
  companyID,
  name,
  absolute = false,
}: {
  lang: string;
  companyID: number;
  name: string;
  absolute?: boolean;
}) => {
  return `/${lang}/company/${encodeCompanyIDHash(companyID)}/${slugify(name)}`;
};

// For debugging...
if (typeof window == 'object') {
  const wnd = window as any;
  if (!wnd._dbg) wnd._dbg = {};
  wnd._dbg.nextApiRequest = nextApiRequest;
}

// Dirty way of getting rid of hookstate objects...
// NOTE1! Doesn't support dates etc. etc.
// NOTE2! lodash cloneDeep doesn't work, it thinks everything is an object
export function cloneState<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export const companyUrlParts = (url: string) => ({ hash: url?.split('/')[3], slug: url?.split('/')[4] });

/** Send a file to the API */
export const sendFile = async (
  file: File | Blob,
  onProgress: (percentComplete: number) => void,
  endpoint: string
): Promise<ApiRequestResponse> =>
  new Promise<ApiRequestResponse>((resolve, reject) => {
    if (!file) {
      return resolve({ status: 'no file to upload' });
    }

    const req = new XMLHttpRequest();
    req.open('POST', process.browser ? endpoint : 'http://127.0.0.1:3001' + endpoint, true);
    // req.setRequestHeader('upload-filename', btoa(file.name)); // Base 64 encode this

    req.responseType = 'json';

    req.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentComplete = event.loaded / event.total;
        console.log('progress', Math.round(percentComplete * 1000) / 10 + '%');
        onProgress(percentComplete);
      }
    });

    req.upload.addEventListener('abort', (e) => console.log('The transfer has been canceled by the user.'));

    req.onload = function (event) {
      // Verify that we did get a json response and that it is a framework standard response (object with status)
      // NOTE! typeof null == "object"
      let responseObject =
        typeof this.response == 'object' && this.response && this.response.status ? this.response : { status: 'illegal response' };

      // Callback, no matter what we should return { status: "ok/error reason", ....}
      return resolve(responseObject);
    };

    req.send(file);
  });

export const imageFromCDN = (path: string, options: { [key: string]: string | number } = null) => {
  // If this is a dev environment, serve the images locally
  const cdnEndpoint = process.env.NEXT_PUBLIC_CDN_ENDPOINT;
  if (!cdnEndpoint) return path;

  const url = new URL(`${process.env.NEXT_PUBLIC_CDN_ENDPOINT}${path}`);

  if (options) {
    for (const key in options) {
      url.searchParams.append(key, `${options[key]}`);
    }
  }

  return url.toString();
};
