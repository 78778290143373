import type { WWWList, WWWListContactPerson, WWWListGroup } from '../common/documents/WWWList';
import type { WWWProjectMenuItem } from '../common/documents/WWWProject';
import { nextApiRequest } from './utils';

interface ContactPerson {
  contactPersonID?: number;
  name?: string;
  title?: string;
  email?: string;
  phone?: string;
  role?: string;
}

export const addListContactPerson = async ({
  listID,
  companyID,
  projectID = null,
  contactPersonID,
  token,
  isInListView = false,
  ...listContactPerson
}: { listID: number; companyID: number; projectID?: number; token?: string; isInListView?: boolean } & ContactPerson): Promise<{
  status: string;
  lists?: WWWList[];
  listGroups?: WWWListGroup[];
  projectsMenuItems?: WWWProjectMenuItem[];
  listContactPersons?: WWWListContactPerson[];
  usedContactPersonIDs?: number[];
}> => {
  const payload: { [key: string]: any } = {
    listID,
    companyID,
    projectID,
    token,
  };

  if (contactPersonID) {
    // Adding company contact person
    payload.listContactPerson = {
      contactPersonID,
      role: listContactPerson.role || '',
    };
  } else {
    // Adding manual list contact person
    payload.listContactPerson = listContactPerson;
  }

  let url = '/api/v1/www/contact-persons/add';
  if (isInListView) url = '/api/v1/www/list/item-contact-person-add';

  return await nextApiRequest(url, payload);
};

export const updateListContactPerson = async ({
  listID,
  companyID,
  projectID = null,
  listContactPersonID,
  contactPersonID,
  token,
  isInListView = false,
  ...listContactPerson
}: {
  listID: number;
  companyID: number;
  projectID?: number;
  listContactPersonID: number;
  token?: string;
  isInListView?: boolean;
} & ContactPerson): Promise<{
  status: string;
  lists?: WWWList[];
  listGroups?: WWWListGroup[];
  projectsMenuItems?: WWWProjectMenuItem[];
  listContactPersons?: WWWListContactPerson[];
  usedContactPersonIDs?: number[];
}> => {
  const payload: { [key: string]: any } = {
    listID,
    companyID,
    projectID,
    token,
  };

  if (contactPersonID) {
    // Editing company contact person
    payload.listContactPerson = {
      listContactPersonID,
      contactPersonID,
      role: listContactPerson.role || '',
    };
  } else {
    // Editing manual list contact person
    payload.listContactPerson = {
      listContactPersonID,
      ...listContactPerson,
    };
  }

  let url = '/api/v1/www/contact-persons/update';
  if (isInListView) url = '/api/v1/www/list/item-contact-person-update';

  return await nextApiRequest(url, payload);
};

export const deleteListContactPerson = async ({
  listID,
  companyID,
  projectID = null,
  token,
  isInListView = false,
  listContactPersonID,
}: {
  listID: number;
  companyID: number;
  projectID?: number;
  token?: string;
  isInListView?: boolean;
  listContactPersonID: number;
}): Promise<{ status: string; lists?: WWWList[]; listContactPersons?: WWWListContactPerson[]; usedContactPersonIDs?: number[] }> => {
  let url = '/api/v1/www/contact-persons/delete';
  if (isInListView) url = '/api/v1/www/list/item-contact-person-delete';

  return await nextApiRequest(url, {
    listID,
    companyID,
    projectID,
    token,
    listContactPersonID,
  });
};
