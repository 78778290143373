import { useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { ThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { Layout } from '../components/layout/Layout';
import { MuiTheme } from '../components/Theme';
import PaywallDrawer from '../components/paywall/PaywallDrawer';
import { classificationFetchers } from '../lib/classifications';
import { validateSession } from '../context/loginContext';
import { listsState } from '../context/listsStore';
import { useScopedState } from '../lib/state';
import { StaticState, staticState as staticStore } from '../context/staticStore';
import { loadCookieConsent, parseConsentCookie } from '../lib/cookieConsent';

import '../styles/globals.css';
import 'react-virtualized/styles.css';

// FIXME: Bake translations into the next.js pipeline, they are here just for testing...
const messagesInEnglish = {
  slogan: 'Slogan, but in English',
};
const messagesInSwedish = {
  slogan: 'Samma på Svenska',
};
const messages = {
  en: messagesInEnglish,
  sv: messagesInSwedish,
};

const MyApp = ({ Component, pageProps, router, ...other }) => {
  const [history, setHistory] = useState<{ path: string; query: any }[]>([]);
  const staticState = useScopedState(staticStore);

  /** Keep track of the dynamic loading state of different context options */
  const staticContextItemStateRef = useRef<{ [item: string]: string | boolean }>({});

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    /** Validate user session */
    validateSession({})
      .then(({ userID }) => {
        // Restore lists cache
        if (userID) listsState.getState().doLoadCached({ userID });
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (!history.length || history[history.length - 1].path !== router.route) {
      setHistory([...history, { path: router.route, query: router.query }]);
    }
  }, [router.route]);

  /** Check if a static context item has been preloaded, and if not, load it dynamically */
  const loadStaticContextItem = <T extends keyof StaticState>(key: T, fetcher: () => Promise<Partial<StaticState>>) => {
    const staticContextItemState = staticContextItemStateRef.current;

    if (staticContextItemStateRef.current[key] === 'ok') return;

    /** If item has been preloaded, assign it now */
    if (!staticState[key].length && pageProps[key]) {
      staticState.set({ [key]: pageProps[key] } as any);
      staticContextItemStateRef.current[key] = 'ok';
      return;
    }

    /** If item has not been loaded, load it now */
    if (!staticState[key].length && !staticContextItemState[key]) {
      console.warn(`⚠️ Loading ${key} at runtime...`);

      staticContextItemStateRef.current[key] = 'loading';

      fetcher()
        .then((data) => {
          if (!data[key].length) return;
          staticState.set({ [key]: data[key] } as any);
          staticContextItemStateRef.current[key] = 'ok';
        })
        .catch((error) => {
          console.error(error);
          staticContextItemStateRef.current[key] = false;
        });
    }
  };

  /** Check if all dynamic data has been loaded, if not, load it */
  // TODO: Move into hookstate global state...
  useEffect(() => {
    for (const key of Object.keys(classificationFetchers) as Array<keyof StaticState>) {
      loadStaticContextItem(key, classificationFetchers[key]);
    }
  }, []);

  const isSSR = typeof window === 'undefined';

  const cookieConsent = loadCookieConsent();
  let marketingConsent = 'denied';
  let statisticsConsent = 'denied';
  if (cookieConsent) {
    const { marketing, statistics } = parseConsentCookie(cookieConsent);
    if (marketing) marketingConsent = 'granted';
    if (statistics) statisticsConsent = 'granted';
  }

  // Cookie consent
  const CookieConsent = isSSR || !!cookieConsent ? null : dynamic(() => import('../components/CookieConsent'));

  return (
    <>
      <Head>
        <title>Constle</title>
      </Head>

      {/**
       * Google Analytics
       * @see https://nextjs.org/docs/messages/next-script-for-ga
       */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
      />
      <Script id="google-analytics" strategy="afterInteractive">{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('consent', 'default', {
          'ad_storage': '${marketingConsent}',
          'analytics_storage': '${statisticsConsent}',
        });
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
      `}</Script>

      {/* Facebook Pixel */}
      <Script strategy="lazyOnload">{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '348784999959482');
        fbq('track', 'PageView');
      `}</Script>

      <ThemeProvider theme={MuiTheme}>
        <IntlProvider messages={messages[router.locale]} locale={router.locale} defaultLocale="fi">
          {CookieConsent && <CookieConsent />}
          <PaywallDrawer />

          <Layout history={history}>
            <Component {...pageProps} />
          </Layout>
        </IntlProvider>
      </ThemeProvider>
    </>
  );
};

export default MyApp;
