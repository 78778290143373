import React, { FC, MouseEvent, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css, keyframes } from 'styled-components';
import type { UrlObject } from 'url';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountIcon from 'mdi-react/AccountCircleIcon';
import StoreIcon from 'mdi-react/StoreIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import BellIcon from 'mdi-react/BellIcon';
import ClipboardTextIcon from 'mdi-react/ClipboardTextMultipleIcon';
import BriefcasePlusIcon from 'mdi-react/BriefcasePlusIcon';
import LogoutVariantIcon from 'mdi-react/LogoutVariantIcon';
import LoginIcon from 'mdi-react/LoginVariantIcon';
import AccountBoxMultipleIcon from 'mdi-react/AccountBoxMultipleIcon';
import FolderMultipleIcon from 'mdi-react/FolderMultipleIcon';
import { AccountUserAccessRole } from '../../common/Constants';
import { logoutUser } from '../../context/loginContext';
import { useUser } from '../../context/useUser';
import { companyUrlParts } from '../../lib/utils';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { CompanyLogo } from '../company/CompanyLogo';
import { Color } from '../Theme';

const NavButton = styled(Button)`
  .MuiButton-label {
    color: ${Color.white};

    .wrap {
      max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    svg {
      color: ${Color.primary};
    }
  }
`;

const notificationIconAnimation = keyframes`
  0% { transform: rotate(0deg); }
  20% { transform: rotate(-12deg); }
  50% { transform: rotate(7deg); }
  80% { transform: rotate(-4deg); }
  100% { transform: rotate(0deg); }
`;

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const NotificationIconStyle = styled.figure<{ fancy: boolean; item: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  top: -2px;
  right: -2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Color.red};
  border: 2px solid ${Color.secondary};

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -2px;
    right: -2px;
    width: 20px;
    height: 20px;
    background-color: ${Color.red};
    background: radial-gradient(transparent 10%, ${Color.red} 100%);
    border-radius: 50%;
    opacity: 0;

    animation: ${pulseAnimation} 2s 1s 4 cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  svg {
    transform-origin: 50% 10%;
    opacity: 0.9;

    animation: ${notificationIconAnimation} 1s 2s ease;
  }

  ${({ fancy }) =>
    !fancy &&
    css`
      top: 0px;
      right: 0px;
      width: 16px;
      height: 16px;
      border-color: ${Color.white};

      &::after,
      svg {
        display: none;
      }
    `}

  ${({ item }) =>
    item &&
    css`
      top: -6px;
      left: 14px;
      width: 16px;
      height: 16px;
      border-color: ${Color.white};

      &::after,
      svg {
        display: none;
      }
    `}
`;

const NotificationIcon: FC<{ fancy?: boolean; item?: boolean }> = ({ fancy = false, item = false }) => (
  <NotificationIconStyle fancy={fancy} item={item}>
    <BellIcon size={10} color={Color.white} />
  </NotificationIconStyle>
);

type DataFunction<T> = (data: { user: ReturnType<typeof useUser>; company: ReturnType<typeof useUser>['companies'][number] }) => T;

export interface NavbarItem {
  id: string;
  label: string | JSX.Element | DataFunction<string | JSX.Element>;
  icon: JSX.Element | DataFunction<string | JSX.Element>;
  href?: string | UrlObject | DataFunction<string | UrlObject | null>;
  onClick?: () => void;

  /** Should a notification bell icon be shown */
  notification?: boolean | DataFunction<boolean>;

  /**
   * If the entry has a non-descriptive label, like the users name,
   * it should have an additional descriptor for accessibility
   */
  'aria-label'?: string | { id: string; defaultMessage: string };

  /** Should the icon only be shown on mobile */
  isIconHiddenOnDesktop?: boolean;

  /** When this item should be visible */
  condition?: DataFunction<boolean>;

  subitems?: NavbarItem[];
}

const loginItem: NavbarItem = {
  id: 'navigation-login',
  label: <FormattedMessage id="navigation.logIn" defaultMessage="Kirjaudu / Rekisteröidy" />,
  icon: <LoginIcon />,
  href: '/login',

  /** Only show login if user is logged out */
  condition: ({ user }) => !user.isLoggedIn,
};

const settingsItem: NavbarItem = {
  id: 'navigation-settings',
  label: <FormattedMessage id="navigation.settings" defaultMessage="Käyttäjätili" />,
  icon: <AccountIcon />,
  href: '/settings',

  condition: ({ user }) => user.isLoggedIn,
};

const linkCompanyItem: NavbarItem = {
  id: 'navigation-account-link-company',
  label: <FormattedMessage id="navigation.linkCompany" defaultMessage="Liitä yritys" />,
  icon: <BriefcasePlusIcon />,
  href: '/register/company',

  /** Only show link company, if no company has been linked, and no linking process is currently ongoing */
  condition: ({ user, company }) => user.isLoggedIn && !(company && (company.isPending || !company.isPending)),
};

const logoutItem: NavbarItem = {
  id: 'navigation-logout',
  label: <FormattedMessage id="navigation.logout" defaultMessage="Kirjaudu ulos" />,
  icon: <LogoutVariantIcon />,

  condition: ({ user }) => user.isLoggedIn,

  onClick: async () => {
    await logoutUser();

    // Refresh tab, just in case...
    window.location.href = '/';
  },
};

const accountItem: NavbarItem = {
  id: 'navigation-account',
  label: ({ user }) => user.user.firstname || <FormattedMessage id="navigation.account" defaultMessage="Käyttäjä" />,
  'aria-label': {
    id: 'navigation.account',
    defaultMessage: 'Käyttäjä',
  },
  icon: <AccountIcon />,

  condition: ({ user }) => user.isLoggedIn,

  subitems: [settingsItem, linkCompanyItem, logoutItem],
};

const companyProfileItem: NavbarItem = {
  id: 'navigation-company-profile',
  label: <FormattedMessage id="navigation.companyProfile" defaultMessage="Yrityssivu" />,
  icon: ({ company }) => <CompanyLogo size={22} logo={company.logo} />,
  href: ({ company }) => ({
    pathname: '/company/[hash]/[slug]',
    query: companyUrlParts(company.url),
  }),

  /** Only show company button if company has been fully linked, OR if company manual form is filled and user has admin rights */
  condition: ({ user, company }) =>
    user.isLoggedIn && company && ((company.isPending && user.accessRoleID === AccountUserAccessRole.ADMIN) || !company.isPending),
};

const contactPersonsItem: NavbarItem = {
  id: 'navigation-company-contactPersons',
  label: <FormattedMessage id="navigation.contactPersons" defaultMessage="Yhteyshenkilöt" />,
  icon: <AccountBoxMultipleIcon />,
  href: '/contact-persons',
  notification: ({ user }) => user.isContactPersonInvitePending,

  /** Only show company button if company has been fully linked, OR if company manual form is filled and user has admin rights */
  condition: ({ user, company }) =>
    user.isLoggedIn && company && ((company.isPending && user.accessRoleID === AccountUserAccessRole.ADMIN) || !company.isPending),
};

const companyItem: NavbarItem = {
  id: 'navigation-company',
  label: ({ company }) => company?.name || <FormattedMessage id="navigation.company" defaultMessage="Yritys" />,
  'aria-label': {
    id: 'navigation.company',
    defaultMessage: 'Yritys',
  },
  icon: <StoreIcon />,

  /** Only show company button if company has been fully linked, OR if company manual form is filled and user has admin rights */
  condition: ({ user, company }) =>
    user.isLoggedIn && company && ((company.isPending && user.accessRoleID === AccountUserAccessRole.ADMIN) || !company.isPending),

  subitems: [companyProfileItem, contactPersonsItem],
};

const projectsItem: NavbarItem = {
  id: 'navigation-projects',
  label: <FormattedMessage id="navigation.projects" defaultMessage="Projektit" />,
  icon: <FolderMultipleIcon />,
  href: '/tyomaat',

  condition: ({ user }) => user.isLoggedIn,
};

// FIXME: Remove this once the landing page at "/tyomaat" is complete...
const projectsItemDummy: NavbarItem = {
  id: 'navigation-projects-dummy',
  label: <FormattedMessage id="navigation.projects" defaultMessage="Projektit" />,
  icon: <FolderMultipleIcon />,
  href: '/login',

  condition: ({ user }) => !user.isLoggedIn,
};

const listsItem: NavbarItem = {
  id: 'navigation-lists',
  label: <FormattedMessage id="navigation.lists" defaultMessage="Listat" />,
  icon: <ClipboardTextIcon />,
  href: '/lists',

  condition: ({ user }) => user.isLoggedIn,
};

// FIXME: Remove this once the landing page at "/tyomaat" is complete...
const listsItemDummy: NavbarItem = {
  id: 'navigation-lists-dummy',
  label: <FormattedMessage id="navigation.lists" defaultMessage="Listat" />,
  icon: <ClipboardTextIcon />,
  href: '/login',

  condition: ({ user }) => !user.isLoggedIn,
};

const mobileMenuItem: NavbarItem = {
  id: 'navigation-mobile-menu',
  label: <FormattedMessage id="navigation.mobileMenu" defaultMessage="Valikko" />,
  icon: <MenuIcon />,

  condition: ({ user }) => user.isLoggedIn,
};

/** The list of items to show in navbar, in order */
export const navigationItems: readonly NavbarItem[] = [
  listsItem,
  listsItemDummy,
  projectsItem,
  projectsItemDummy,
  companyItem,
  accountItem,
  loginItem,
] as const;

const checkForNotification = (item: NavbarItem, dataFunctionProps: Parameters<DataFunction<any>>[0]): boolean => {
  const isNotification = typeof item.notification === 'function' ? item.notification(dataFunctionProps) : item.notification || false;
  if (isNotification) return true;

  if (item.subitems && item.subitems.length) {
    return item.subitems.some((subitem) => checkForNotification(subitem, dataFunctionProps));
  }

  return false;
};

/** Helper component to render mobile menu, allows for the use of dynamic imports */
const MobileNavigationMenu: FC<{ items: JSX.Element[]; open: boolean; onClose: () => void }> = ({ items, open, onClose }) => {
  const isSmall = useBreakPoint('small');

  const Drawer = useMemo(() => (isSmall ? dynamic(() => import('@material-ui/core/Drawer')) : null), [isSmall]);
  const List = useMemo(() => (isSmall ? dynamic(() => import('@material-ui/core/List')) : null), [isSmall]);

  // If in desktop mode, render nothing
  if (!isSmall) return null;

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <List style={{ width: 280 }}>{items}</List>
    </Drawer>
  );
};

interface NavigationItemProps {
  label: string | JSX.Element;
  'aria-label': string;
  href: string | UrlObject;
  icon: string | JSX.Element;
  isNotification: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

const NavigationItemButton = forwardRef<
  HTMLButtonElement,
  { item: NavbarItem; processNavigationItem: (item: NavbarItem) => NavigationItemProps }
>(({ item, processNavigationItem }, ref) => {
  const itemProps = processNavigationItem(item);
  if (!itemProps) return null;

  const { label, href, icon, isNotification, ...buttonProps } = itemProps;

  const itemButton = (
    <NavButton color="primary" endIcon={icon} {...buttonProps} ref={ref}>
      {isNotification && <NotificationIcon fancy />}
      <span className="wrap">{label}</span>
    </NavButton>
  );

  if (href) {
    return (
      <Link href={href} passHref>
        {itemButton}
      </Link>
    );
  } else {
    return itemButton;
  }
});

const NavigationItemListItem = forwardRef<
  HTMLDivElement,
  { item: NavbarItem; processNavigationItem: (item: NavbarItem) => NavigationItemProps }
>(({ item, processNavigationItem }, ref) => {
  const itemProps = processNavigationItem(item);
  if (!itemProps) return null;

  const { label, href, icon, isNotification, ...listItemProps } = itemProps;

  const listItem = (
    <ListItem button {...listItemProps} ref={ref}>
      <ListItemIcon style={{ position: 'relative' }}>
        {isNotification && <NotificationIcon item />}
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );

  if (href) {
    return (
      <Link href={href} passHref>
        {listItem}
      </Link>
    );
  } else {
    return listItem;
  }
});

/**
 * Handles all logic with navbar item processing & rendering
 */
export const useNavigationItems = () => {
  const user = useUser();
  const intl = useIntl();
  const isSmall = useBreakPoint('small');

  const [menu, setMenu] = useState<{ id: string; items: NavbarItem[] } | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Close the mobile menu if screen size changes to desktop
  useEffect(() => {
    setIsDrawerOpen((state) => state && isSmall);
  }, [isSmall]);

  const handleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const dataFunctionProps = useMemo(
    () => ({ user, company: user.user.companies[0] || null }),
    [user.isLoggedIn, user.isSSRRender, user.companies]
  );

  /** Process navigation item info and determine, if it has to be rendered or not */
  const processNavigationItem = useCallback(
    (item: NavbarItem): NavigationItemProps => {
      const { id, label, icon, href, 'aria-label': aria, onClick, condition, subitems } = item;

      if (condition && !condition(dataFunctionProps)) return null;

      const labelText = typeof label === 'function' ? label(dataFunctionProps) : label;
      const iconElement = typeof icon === 'function' ? icon(dataFunctionProps) : icon;
      const hrefText = typeof href === 'function' ? href(dataFunctionProps) : href;
      const ariaLabelText = aria ? (typeof aria === 'string' ? aria : intl.formatMessage(aria)) : null;
      const isNotification = checkForNotification(item, dataFunctionProps);

      const handleMenuOpen = (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        setMenu({ id, items: subitems });
        setMenuAnchor(event.currentTarget);
      };

      const handleClick = (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        if (onClick) onClick();
        if (subitems && subitems.length) handleMenuOpen(event);
        if (id !== 'navigation-mobile-menu') handleDrawerClose();
        if (!subitems) setMenuAnchor(null);
      };

      return {
        label: labelText,
        'aria-label': ariaLabelText,
        href: hrefText,
        icon: iconElement,
        isNotification,
        onClick: handleClick,
      };
    },
    [dataFunctionProps]
  );

  /** Render a single navbar item as a button */
  const renderNavigationItemAsButton = useCallback(
    (item: NavbarItem) => <NavigationItemButton key={item.id} item={item} processNavigationItem={processNavigationItem} />,
    [processNavigationItem]
  );

  /** Render a single navbar item as a list item */
  const renderNavigationItemAsListItem = useCallback(
    (item: NavbarItem) => <NavigationItemListItem key={item.id} item={item} processNavigationItem={processNavigationItem} />,
    [processNavigationItem]
  );

  /** Render the menu that will handle navbar subitems */
  const renderNavigationMenu = useCallback(() => {
    if (!menu) return null;

    const { id, items } = menu;

    const Menu = dynamic(() => import('@material-ui/core/Menu'));

    const handleClose = () => {
      setMenuAnchor(null);
    };

    return (
      <Menu id={`${id}-menu`} key={`${id}-menu`} anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleClose}>
        {items.map(renderNavigationItemAsListItem)}
      </Menu>
    );
  }, [menu, menuAnchor]);

  /** Render dektop navigation bar items */
  const renderDesktopNavigation = useCallback(
    () => [...navigationItems.map(renderNavigationItemAsButton), renderNavigationMenu()],
    [menu, menuAnchor, dataFunctionProps]
  );

  /** Render mobile navigation bar items & menu */
  const renderMobileNavigation = useCallback(() => {
    const menuButton = {
      ...mobileMenuItem,
      onClick: handleDrawerOpen,
    };

    const flattenedMenuItems = navigationItems
      .map(({ subitems, ...item }) => {
        if (subitems) {
          const labelText = typeof item.label === 'function' ? item.label(dataFunctionProps) : item.label;
          const ariaLabelText = item['aria-label']
            ? typeof item['aria-label'] === 'string'
              ? item['aria-label']
              : intl.formatMessage(item['aria-label'])
            : null;

          return [
            <ListSubheader key={item.id}>{ariaLabelText || labelText}</ListSubheader>,
            ...subitems.map(renderNavigationItemAsListItem),
          ];
        } else {
          return renderNavigationItemAsListItem(item);
        }
      })
      .flat();

    const menu = (
      <MobileNavigationMenu key="mobile-navigation-menu" items={flattenedMenuItems} open={isDrawerOpen} onClose={handleDrawerClose} />
    );

    return [renderNavigationItemAsButton(loginItem), renderNavigationItemAsButton(menuButton), menu];
  }, [isDrawerOpen, dataFunctionProps]);

  return {
    navigationItems,
    renderDesktopNavigation,
    renderMobileNavigation,
  };
};
