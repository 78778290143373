import { FC, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import EmailCheckIcon from 'mdi-react/EmailCheckIcon';
import { pollLogin } from '../../context/loginContext';
import { Error } from '../Error';
import { Color, borderRadius } from '../Theme';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background-color: ${Color.background};
  border-radius: ${borderRadius}px;

  > .mdi-icon {
    margin-bottom: 16px;
    opacity: 0.7;
  }

  > h6,
  > p {
    text-align: center;
  }

  > p {
    opacity: 0.8;
  }
`;

export const AuthLoginPoll: FC<{ token: string; isLink?: boolean; isDialog?: boolean; onLogin?: VoidFunction }> = ({
  token,
  isLink = false,
  isDialog = false,
  onLogin,
}) => {
  const router = useRouter();
  const [error, setError] = useState<string | JSX.Element | null>(null);

  const login = useCallback(() => {
    if (onLogin) onLogin();
    if (isDialog) return;

    // If "to" parameter is set, navigate there
    // Make sure "to" can only navigate to a local subpath, "@" would make it possbile to navigate anywhere
    if (router.query.to && !router.query.to.includes('@')) {
      return router.replace(`/${router.query.to}`);
    }

    router.push('/');
  }, [router.query.to, isDialog, onLogin]);

  useEffect(() => {
    if (!token) return;

    // Save token to local storage so we can detect if the user opens the login link in this browser
    if (!isLink) localStorage.setItem('constle.login.token', token);

    const timeout = setInterval(async () => {
      const { status, error } = await pollLogin({ token });
      if (status === 'invalid token') return;
      if (status === 'not authenticated') return;
      if (status === 'ok') login();
      if (error) setError(error);
    }, 4000);
    return () => clearInterval(timeout);
  }, [token, login]);

  if (error) return <Error error={error} />;

  return (
    <Content>
      <EmailCheckIcon size={32} />
      {!isLink && <Typography variant="h6">Kirjautumislinkki lähetetty sähköpostitse</Typography>}
      {isLink && <Typography variant="h6">Kirjaudutaan sisään…</Typography>}
      {!isLink && <Typography variant="body2">Tämä ikkuna kirjautuu automaattisesti sisään linkkiä klikatessa.</Typography>}
    </Content>
  );
};
