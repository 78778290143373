import { gaConsent } from './ga';

/**
 * Do cookie consent changes here
 * - Google Analytics (DOES use cookies)
 * - Sentry (does NOT use cookies)
 */

export enum CookieConsentType {
  STATISTICS = 1,
  MARKETING = 2,
}

const getCookieConsentValue = (name: string) => (typeof window === 'undefined' ? null : window.localStorage.getItem(name));

const setCookieConsentValue = (name: string, value: string) => {
  window.localStorage.setItem(name, value);
};

export const loadCookieConsent = () => getCookieConsentValue('constleconsent');

export const saveCookieConsent = (consent: CookieConsentType[]) => {
  setCookieConsentValue('constleconsent', consent.join(','));
};

export const parseConsentCookie = (cookieValue: string) => {
  const cookieTypes = cookieValue.split(',').map((value) => parseInt(value));
  return { statistics: cookieTypes.includes(CookieConsentType.STATISTICS), marketing: cookieTypes.includes(CookieConsentType.MARKETING) };
};

export const changeCookieConsent = (consent: CookieConsentType[]) => {
  const isStatisticsAllowed = consent.includes(CookieConsentType.STATISTICS);
  const isMarketingAllowed = consent.includes(CookieConsentType.MARKETING);

  gaConsent(isStatisticsAllowed, isMarketingAllowed);
};
