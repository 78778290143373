import create from 'zustand';
import { combine } from 'zustand/middleware';
import { gaEvent } from '../lib/ga';

export type PaywallState = {
  isOpen: boolean;
  isClosed: boolean;
};

export const paywallStateDefaults: PaywallState = {
  isOpen: false,
  isClosed: false,
};

/** Store */
export const paywallState = create(combine(paywallStateDefaults, (set) => ({ set })));

export const openPaywall = (from: string = '') => {
  if (paywallState.getState().isClosed) return false;

  // Only log event if paywall was closed
  if (!paywallState.getState().isOpen) {
    gaEvent(`open_paywall${from ? `_${from}` : ''}`, {
      event_category: 'engagement',
      event_label: `open paywall${from ? ` from ${from}` : ''}`,
    });
  }

  paywallState.setState({ isOpen: true });

  return true;
};
