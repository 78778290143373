import { CSSProperties, FC } from 'react';
import Typography from '@material-ui/core/Typography';
import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';

export const Help: FC<{ style?: CSSProperties }> = ({ style = {}, children }) => (
  <Typography
    variant="caption"
    display="block"
    style={{ position: 'relative', marginTop: '0.4rem', paddingLeft: '1.3rem', ...style }}
    color="textSecondary"
  >
    <InfoOutlineIcon size={16} style={{ position: 'absolute', left: 0, marginTop: 1 }} /> {children}
  </Typography>
);
