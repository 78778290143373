import { memo } from 'react';
import styled from 'styled-components';
import StoreIcon from 'mdi-react/StoreIcon';
import { WWWCompanyDocument } from '../../common/documents/WWWCompanyDocument';
import { Color, borderRadius } from '../Theme';

export const Logo = styled.figure<{ size?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: ${({ size }) => size || 42}px;
  height: ${({ size }) => size || 42}px;
  background-color: ${Color.background};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: ${borderRadius}px;
  overflow: hidden;
  svg {
    color: ${Color.disabled};
  }
`;

export interface CompanyLogoProps extends Pick<WWWCompanyDocument, 'logo'> {
  size?: number;
  icon?: JSX.Element;
  absolutePath?: boolean;
}

const areLogosEqual = (prev: CompanyLogoProps, next: CompanyLogoProps) => {
  if (prev.logo?.path !== next.logo?.path) return false;
  return true;
};

export const CompanyLogo = memo<CompanyLogoProps>(({ logo, size = 42, icon, absolutePath = false }) => {
  const isLogo = !!logo?.path;

  const logoStyleProp = isLogo
    ? {
        backgroundImage: absolutePath ? `url(${logo.path})` : `url(/api/v1/files/public/42x42M-${logo.path})`,
        backgroundColor: 'white',
        border: 'none',
      }
    : {};

  return (
    <Logo aria-hidden className="logo" size={size} style={logoStyleProp}>
      {!isLogo && (icon || <StoreIcon />)}
    </Logo>
  );
}, areLogosEqual);
