import React, { FC } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { Color, layoutStyles, mediaQuery } from '../Theme';
import { Logo } from '../Logo';
import { FormattedMessage, useIntl } from 'react-intl';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { darken, desaturate } from 'polished';
import { Button } from '../Button';
import { Header } from '../Header';
import { useUser } from '../../context/useUser';

const Styled = {
  Bar: styled.footer<{ dialogPage: boolean }>`
    position: relative;
    width: 100%;
    background-color: ${desaturate(0.5, Color.secondary)};

    ${({ dialogPage }) =>
      dialogPage &&
      mediaQuery.small(css`
        > * :not(:last-child) {
          display: none;
        }
      `)}

    h1 {
      margin: 0;
      color: ${Color.secondary};

      svg {
        opacity: 0.9;
      }

      small {
        display: block;
        margin-top: 0.25rem;
        font-size: 0.875rem;
        font-weight: normal;
        color: ${Color.white};
        opacity: 0.5;

        span {
          margin: 0 0.4rem;
        }
      }
    }

    ${mediaQuery.small(css`
      h1 {
        text-align: center;
      }
    `)}
  `,
};

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  ${layoutStyles}
  padding: 3rem 0;

  > div {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    // text-align: right;
    max-width: 300px;

    &:last-child {
      align-self: center;
    }

    h2 {
      margin-bottom: 0.75rem;
      color: ${Color.white};
      opacity: 0.75;
    }

    p {
      margin: 0 0 1rem 0;
      color: ${Color.white};
      opacity: 0.4;
    }

    > a:not(.MuiButton-root) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0.4rem 0 0 0;
      color: ${Color.primary};

      svg {
        margin-left: 0.25rem;
      }
    }
  }

  ${mediaQuery.small(css`
    flex-direction: column;

    > div {
      margin-top: 2rem;
      max-width: none;
    }

    .MuiButton-root {
      margin-top: 1rem;
    }
  `)}
`;

const InfoBar = styled.div<{ dialogPage: boolean }>`
  padding: 1rem 0;
  width: 100%;
  max-width: none;
  background-color: ${darken(0.1, Color.secondary)};
  color: ${Color.white};

  ${({ dialogPage }) =>
    dialogPage &&
    mediaQuery.small(css`
      padding: 1rem 1rem;
      border-top: 2px solid ${Color.background};
      background-color: ${Color.white};
      color: ${Color.black};

      a {
        color: ${Color.black} !important;
      }
    `)}

  ${Content} {
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    small {
      font-size: 0.875rem;
      opacity: 0.5;
    }

    > div > a {
      margin: 0.4rem 0;
      color: ${Color.white};
      opacity: 0.5;
    }

    ${mediaQuery.small(css`
      flex-direction: row;

      > div {
        margin: 0;
      }
    `)}
  }
`;

const currentYear = new Date().getFullYear();

export const Footer: FC<{ dialogPage: boolean }> = ({ dialogPage }) => {
  const intl = useIntl();
  const { isLoggedIn } = useUser();

  return (
    <Styled.Bar dialogPage={dialogPage}>
      <Content>
        <h1>
          <Link href="/">
            <Logo color={Color.white} />
          </Link>
          <small>Kaikki rakentajat ulottuvillasi</small>
        </h1>

        <div>
          <Header small>Meistä</Header>
          <p>Tietokantamme kattaa +100 000 rakennusalalla toimivan yrityksen tiedot sellaisella laajuudella jota ei ole ennen nähty.</p>

          <Link href="/about-us">
            <a>
              <FormattedMessage id="footer.aboutUsLink" defaultMessage="Tutustu Constleen" />
              <ArrowRightIcon size={16} />
            </a>
          </Link>
        </div>

        {/* <div>
          <Header small>Yrityksille</Header>
          <p>Ota alasi parhaat työkeikat ja kumppanit haltuun hakemalla ilmaiseksi Constlen avoimeen rakentajatietopankkiin.</p>

          {!isLoggedIn && (
            <Link href="/login">
              <a>
                <FormattedMessage id="footer.registerLink" defaultMessage="Liity jäseneksi" />
                <ArrowRightIcon size={16} />
              </a>
            </Link>
          )}

          {isLoggedIn && (
            <Link href="/register/company">
              <a>
                <FormattedMessage id="footer.registerCompanyLink" defaultMessage="Liitä yritys" />
                <ArrowRightIcon size={16} />
              </a>
            </Link>
          )}
        </div> */}

        <div>
          <Button color="default" endIcon={<ArrowRightIcon />} href="/contact-us">
            <FormattedMessage id="footer.contactUsButton" defaultMessage="Ota yhteyttä" />
          </Button>
        </div>
      </Content>

      <InfoBar dialogPage={dialogPage}>
        <Content>
          <small>&#169; {currentYear} Constle</small>

          <div>
            <Link href="/privacy-policy">
              <a>
                <FormattedMessage id="footer.privacyPolicyLink" defaultMessage="Tietosuojaseloste" />
                <ArrowRightIcon size={16} />
              </a>
            </Link>
          </div>
        </Content>
      </InfoBar>
    </Styled.Bar>
  );
};
